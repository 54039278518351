import {
  ADMIN_SUMMARY,
  ADMIN_SUMMARY_SUCCESS,
  ALL_ORGANIZATIONS,
  ALL_ORGANIZATIONS_SUCCESS,
  ALL_PUBLISHERS,
  ALL_PUBLISHERS_SUCCESS,
  ASSOCIATE_ORGANIZATION,
  ASSOCIATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_ERROR,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_ERROR,
  DELETE_PUBLISHER_SUCCESS,
  DISSOCIATE_ORGANIZATION,
  DISSOCIATE_ORGANIZATION_SUCCESS,
  ENTRIES_CREATION_REPORT,
  ENTRIES_CREATION_REPORT_SUCCESS,
  PUBLISHERS_REGISTRATION_REPORT,
  PUBLISHERS_REGISTRATION_REPORT_SUCCESS,
  READER_LOGS_REPORT,
  READER_LOGS_REPORT_SUCCESS,
  RESTORE_PUBLISHER,
  RESTORE_PUBLISHER_ERROR,
  RESTORE_PUBLISHER_SUCCESS,
  TEMPLATES_USAGE_REPORT,
  TEMPLATES_USAGE_REPORT_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
} from 'redux/contants';

export const adminSummary = () => ({
  type: ADMIN_SUMMARY,
  payload: {},
});

export const adminSummarySuccess = (summary) => ({
  type: ADMIN_SUMMARY_SUCCESS,
  payload: summary,
});

export const getTemplatesUsageReport = (args) => ({
  type: TEMPLATES_USAGE_REPORT,
  payload: { args },
});

export const getTemplatesUsageReportSuccess = (report) => ({
  type: TEMPLATES_USAGE_REPORT_SUCCESS,
  payload: report,
});

export const getPublishersRegistrationReport = (args) => ({
  type: PUBLISHERS_REGISTRATION_REPORT,
  payload: { args },
});

export const getPublishersRegistrationReportSuccess = (report) => ({
  type: PUBLISHERS_REGISTRATION_REPORT_SUCCESS,
  payload: report,
});

export const getEntriesCreationReport = (args) => ({
  type: ENTRIES_CREATION_REPORT,
  payload: { args },
});

export const getEntriesCreationReportSuccess = (report) => ({
  type: ENTRIES_CREATION_REPORT_SUCCESS,
  payload: report,
});

export const getReaderLogsReport = (args) => ({
  type: READER_LOGS_REPORT,
  payload: { args },
});

export const getReaderLogsReportSuccess = (report) => ({
  type: READER_LOGS_REPORT_SUCCESS,
  payload: report,
});

export const allPublishers = (args) => ({
  type: ALL_PUBLISHERS,
  payload: { args },
});

export const allPublishersSuccess = (publishers) => ({
  type: ALL_PUBLISHERS_SUCCESS,
  payload: publishers,
});

export const deletePublisher = (args) => ({
  type: DELETE_PUBLISHER,
  payload: { args },
});

export const deletePublisherSuccess = (publishers) => ({
  type: DELETE_PUBLISHER_SUCCESS,
  payload: publishers,
});

export const deletePublisherError = () => ({
  type: DELETE_PUBLISHER_ERROR,
  payload: {},
});

export const restorePublisher = (args) => ({
  type: RESTORE_PUBLISHER,
  payload: { args },
});

export const restorePublisherSuccess = (publishers) => ({
  type: RESTORE_PUBLISHER_SUCCESS,
  payload: publishers,
});

export const restorePublisherError = () => ({
  type: RESTORE_PUBLISHER_ERROR,
  payload: {},
});

export const createOrganization = (args, history) => ({
  type: CREATE_ORGANIZATION,
  payload: { args, history },
});

export const createOrganizationSuccess = () => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  payload: {},
});

export const createOrganizationError = () => ({
  type: CREATE_ORGANIZATION_ERROR,
  payload: {},
});

export const allOrganizations = (args) => ({
  type: ALL_ORGANIZATIONS,
  payload: { args },
});

export const allOrganizationsSuccess = (organizations) => ({
  type: ALL_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const deleteOrganization = (args) => ({
  type: DELETE_ORGANIZATION,
  payload: { args },
});

export const deleteOrganizationSuccess = () => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  payload: {},
});

export const updateOrganization = (args, history) => ({
  type: UPDATE_ORGANIZATION,
  payload: { args, history },
});

export const updateOrganizationSuccess = () => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload: {},
});

export const associateOrganization = (args) => ({
  type: ASSOCIATE_ORGANIZATION,
  payload: { args },
});

export const associateOrganizationSuccess = () => ({
  type: ASSOCIATE_ORGANIZATION_SUCCESS,
  payload: {},
});

export const dissociateOrganization = (args) => ({
  type: DISSOCIATE_ORGANIZATION,
  payload: { args },
});

export const dissociateOrganizationSuccess = () => ({
  type: DISSOCIATE_ORGANIZATION_SUCCESS,
  payload: {},
});
