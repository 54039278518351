const { gql } = require('@apollo/client');

const GET_TASKS_QUERY = gql(`
  query($statusFilter: String, $limit: Int, $page: Int){
    getTasks(statusFilter: $statusFilter, limit: $limit, page: $page) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

const GET_TASKS_ADMIN_QUERY = gql(`
  query($statusFilter: String, $limit: Int, $page: Int){
    getTasksAdmin(statusFilter: $statusFilter, limit: $limit, page: $page) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

const ADD_TASK_QUERY = gql(`
  mutation($statusFilter: String, $limit: Int, $page: Int, $taskSlug: String!, $taskType: String!, $text: String, $filename: String, $filenameOriginal: String, $fileSize: Int, $fileType: String, $metadata: String){
    addTask(statusFilter: $statusFilter, limit: $limit, page: $page, task_slug: $taskSlug, task_type: $taskType, text: $text, filename: $filename, filename_original: $filenameOriginal, file_size: $fileSize, file_type: $fileType, metadata: $metadata) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

const UPDATE_TASK_QUERY = gql(`
  mutation($statusFilter: String, $limit: Int, $page: Int, $id: ID!, $taskSlug: String!, $taskType: String!, $text: String, $filename: String, $filenameOriginal: String, $fileSize: Int, $fileType: String, $metadata: String){
    updateTask(statusFilter: $statusFilter, limit: $limit, page: $page, id: $id, task_slug: $taskSlug, task_type: $taskType, text: $text, filename: $filename, filename_original: $filenameOriginal, file_size: $fileSize, file_type: $fileType, metadata: $metadata) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

const GET_TASK_URL_QUERY = gql(`
  query($type: String!){
    getTaskUrl(type: $type) {
      filename, url
    }
  }`);

const DOWNLOAD_TASK_QUERY = gql(`
  query($task_id: Int!){
    downloadTask(task_id: $task_id) {
      body, status, filename, filename_original
    }
  }`);

const DELETE_TASK_QUERY = gql(`
  mutation($statusFilter: String, $limit: Int, $page: Int, $deleteTaskId: ID!, $metadata: String){
    deleteTask(statusFilter: $statusFilter, limit: $limit, page: $page, id: $deleteTaskId, metadata: $metadata) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

const UPDATE_TASK_STATUS_QUERY = gql(`
  mutation($statusFilter: String, $limit: Int, $page: Int, $metadata: String, $status: Boolean!, $comments: String!, $updateTaskStatusId: ID!){
    updateTaskStatus(statusFilter: $statusFilter, limit: $limit, page: $page, metadata: $metadata, status: $status, comments: $comments, id: $updateTaskStatusId) {
      page, allPages, count, rows {
        id, name, email, tasks {
          task_slug, status, comments, id, file_size, file_type, task_type, filename, filename_original, metadata, text, status_updated_at, created_at, updated_at, deleted_at, status_updater {
            id, name
          }
        }
      }
    }
  }`);

module.exports = {
  GET_TASKS_QUERY,
  ADD_TASK_QUERY,
  GET_TASK_URL_QUERY,
  DELETE_TASK_QUERY,
  DOWNLOAD_TASK_QUERY,
  GET_TASKS_ADMIN_QUERY,
  UPDATE_TASK_STATUS_QUERY,
  UPDATE_TASK_QUERY,
};
