/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { adminRoot, userRoot } from 'constants/defaultValues';
import {
  getAPIAddress,
  getLocation,
  handleErrorMessage,
  handleSuccessMessage,
  setCurrentUser,
} from 'helpers/Utils';
import { encryptOnClient } from 'services/crypto';
import { apolloClient } from 'redux/sagas';
import {
  registerUserSuccess,
  registerUserError,
  verifyReCaptchaSuccess,
  verifyReCaptchaError,
  readEntrySuccess,
  readEntryError,
  loginUserSuccess,
  loginUserError,
  createReaderLogSuccess,
  resetPasswordSuccess,
  resetPasswordError,
  sendRecoveryCodeSuccess,
  verifyRecoveryCodeSuccess,
  verifyUserSuccess,
  verifyUserError,
  getEntryFileSuccess,
  getEntryFileError,
  createIssueReportSuccess,
} from 'redux/actions';
import {
  CREATE_ISSUE_REPORT,
  CREATE_READER_LOG,
  GET_ENTRY_FILE,
  GET_ORGANIZATION,
  LOGIN_USER,
  LOGOUT_USER,
  READ_ENTRY,
  REGISTER_USER,
  RESET_PASSWORD,
  SEND_RECOVERY_CODE,
  VERIFY_RECAPTCHA,
  VERIFY_RECOVERY_CODE,
  VERIFY_USER,
} from 'redux/contants';
import {
  CREATE_ISSUE_REPORT_QUERY,
  CREATE_READER_LOG_QUERY,
  GET_ENTRY_FILE_QUERY,
  GET_ORGANIZATION_QUERY,
  LOGIN_USER_QUERY,
  READ_ENTRY_QUERY,
  REGISTER_USER_QUERY,
  RESET_PASSWORD_QUERY,
  SEND_RECOVERY_CODE_QUERY,
  VERIFY_RECAPTCHA_QUERY,
  VERIFY_RECOVERY_CODE_QUERY,
  VERIFY_USER_QUERY,
} from './queries';
import { getOrganizationError, getOrganizationSuccess } from './actions';

export function* watchLoginUser() {
  yield takeLatest(LOGIN_USER, loginUserGenerator);
}
const LOGIN_USER_API = async (user) => {
  return apolloClient.mutate({
    mutation: LOGIN_USER_QUERY,
    variables: {
      email: user.email,
      password: user.password,
    },
  });
};
function* loginUserGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const {
      data: { loginUser },
    } = yield call(LOGIN_USER_API, user);
    handleSuccessMessage(
      'Credentials verified. Welcome to SafeWire!',
      'Login Succeeded'
    );
    setCurrentUser(loginUser);
    yield put(loginUserSuccess(loginUser));
    if (loginUser?.user?.admin_guid !== null) {
      history.push(adminRoot);
    } else if (loginUser?.user?.subscriptions.length === 0) {
      history.push(`${userRoot}/subscription/select-pricing-plan`);
    } else {
      history.push(userRoot);
    }
  } catch (e) {
    handleErrorMessage(e, 'Login Error');
    yield put(loginUserError());
  }
}

export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerUserGenerator);
}
export const REGISTER_USER_API = async (args) => {
  return apolloClient.mutate({
    mutation: REGISTER_USER_QUERY,
    variables: {
      email: args?.email,
      password: args?.password,
      name: args?.name,
      adminGuid: args?.adminGuid,
    },
  });
};
export function* registerUserGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const data = yield call(REGISTER_USER_API, user);
    handleSuccessMessage(
      'Your account has been created successfully. Please check your email to activate your account',
      'Account Created'
    );
    yield put(registerUserSuccess());
    history.push('/user/login?registered=1');
  } catch (e) {
    handleErrorMessage(e, 'Registration Error');
    yield put(registerUserError());
  }
}

export function* watchReadEntry() {
  yield takeEvery(READ_ENTRY, readEntryGenerator);
}
export const READ_ENTRY_API = async (args) => {
  return apolloClient.query({
    query: READ_ENTRY_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      url: args?.url,
    },
  });
};
export function* readEntryGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { readEntry },
    } = yield call(READ_ENTRY_API, args);
    yield put(readEntrySuccess(readEntry));
  } catch (e) {
    handleErrorMessage(
      e,
      e?.graphQLErrors[0]?.extensions?.code?.replaceAll('_', ' ')
    );
    yield put(readEntryError(e?.graphQLErrors[0]?.message));
  }
}

export function* watchCreateReaderLog() {
  yield takeEvery(CREATE_READER_LOG, createReaderLogGenerator);
}
const CREATE_READER_LOG_API = async (args) => {
  const IP = await getAPIAddress();
  const LOCATION = await getLocation(IP.ip);
  return apolloClient.mutate({
    mutation: CREATE_READER_LOG_QUERY,
    variables: {
      createReaderLogId: args.id,
      result: args.result,
      ip: IP.ip,
      location: encryptOnClient(`${LOCATION.city}, ${LOCATION.country_name}`),
    },
  });
};
function* createReaderLogGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { createReaderLog },
    } = yield call(CREATE_READER_LOG_API, args);
    yield put(createReaderLogSuccess());
  } catch (e) {
    console.log(e);
  }
}

export function* watchCreateIssueReport() {
  yield takeLatest(CREATE_ISSUE_REPORT, createIssueReportGenerator);
}
const CREATE_ISSUE_REPORT_API = async (args) => {
  const IP = await getAPIAddress();
  const LOCATION = await getLocation(IP.ip);
  return apolloClient.mutate({
    mutation: CREATE_ISSUE_REPORT_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      emailPayload: args.email_payload,
      entryId: args.entry_id,
      organizationId: args.organization_id,
      message: encryptOnClient(args.message),
      email: args.email === '' ? '' : encryptOnClient(args.email),
      type: args.type,
      name: args.name === '' ? '' : encryptOnClient(args.name),
      url: args.url,
      ip: IP.ip,
      location: encryptOnClient(`${LOCATION.city}, ${LOCATION.country_name}`),
    },
  });
};
function* createIssueReportGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { createIssueReport },
    } = yield call(CREATE_ISSUE_REPORT_API, args);
    yield put(createIssueReportSuccess());
    handleSuccessMessage(createIssueReport, 'Success');
  } catch (e) {
    console.log(e);
  }
}

export function* watchVerifyReCaptcha() {
  yield takeEvery(VERIFY_RECAPTCHA, verifyReCaptchaGenerator);
}
const VERIFY_RECAPTCHA_API = async (args) => {
  return apolloClient.mutate({
    mutation: VERIFY_RECAPTCHA_QUERY,
    variables: {
      responseKey: args.responseKey,
    },
  });
};
function* verifyReCaptchaGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { verifyReCaptcha },
    } = yield call(VERIFY_RECAPTCHA_API, args);
    yield put(verifyReCaptchaSuccess());
  } catch (e) {
    handleErrorMessage(
      'Your request has been blocked due to suspicious activity.',
      'Suspicious Activity'
    );
    yield put(verifyReCaptchaError());
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordGenerator);
}
const RESET_PASSWORD_API = async (args) => {
  return apolloClient.mutate({
    mutation: RESET_PASSWORD_QUERY,
    variables: {
      password: args?.newPassword,
      code: args?.code,
    },
  });
};
function* resetPasswordGenerator({ payload }) {
  const { args } = payload;
  const { history } = args;
  try {
    const {
      data: { resetPassword },
    } = yield call(RESET_PASSWORD_API, args);
    yield put(resetPasswordSuccess());
    handleSuccessMessage(resetPassword, 'Reset Password Success');
    history.push('/user/login');
  } catch (e) {
    yield put(resetPasswordError());
    handleErrorMessage(e, 'Reset Password Error', null);
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}
const logoutAsync = async (history) => {
  setCurrentUser(null);
  history.push('/user/login');
};
function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchSendRecoveryCode() {
  yield takeEvery(SEND_RECOVERY_CODE, sendRecoveryCodeGenerator);
}
const SEND_RECOVERY_CODE_API = async (args) => {
  return apolloClient.mutate({
    mutation: SEND_RECOVERY_CODE_QUERY,
    fetchPolicy: 'no-cache',
    variables: { email: args.email },
  });
};
function* sendRecoveryCodeGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { sendRecoveryCode },
    } = yield call(SEND_RECOVERY_CODE_API, args);
    handleSuccessMessage(sendRecoveryCode, 'Recovery Code Sent');
    yield put(sendRecoveryCodeSuccess());
  } catch (e) {
    handleErrorMessage(e, 'Send Recovery Code Error', null);
  }
}

export function* watchVerifyRecoveryCode() {
  yield takeEvery(VERIFY_RECOVERY_CODE, verifyRecoveryCodeGenerator);
}
const VERIFY_RECOVERY_CODE_API = async (args) => {
  return apolloClient.mutate({
    mutation: VERIFY_RECOVERY_CODE_QUERY,
    variables: { code: args.code },
  });
};
function* verifyRecoveryCodeGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { verifyRecoveryCode },
    } = yield call(VERIFY_RECOVERY_CODE_API, args);
    handleSuccessMessage(verifyRecoveryCode, 'Recovery Code Verified');
    yield put(verifyRecoveryCodeSuccess());
  } catch (e) {
    handleErrorMessage(e, 'Verify Recovery Code Error', null);
  }
}

export function* watchVerifyUser() {
  yield takeEvery(VERIFY_USER, verifyUserGenerator);
}
const VERIFY_USER_API = async (args) => {
  return apolloClient.mutate({
    mutation: VERIFY_USER_QUERY,
    variables: { code: args.code },
  });
};
function* verifyUserGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { verifyUser },
    } = yield call(VERIFY_USER_API, args);
    handleSuccessMessage(verifyUser, 'Account Verified');
    yield put(verifyUserSuccess());
  } catch (e) {
    handleErrorMessage(e, 'Account Verification Error', null);
    yield put(verifyUserError());
  }
}

export function* watchGetEntryFile() {
  yield takeEvery(GET_ENTRY_FILE, getEntryFileGenerator);
}
const GET_ENTRY_FILE_API = async (args) => {
  return apolloClient.query({
    query: GET_ENTRY_FILE_QUERY,
    variables: {
      filename: args?.filename,
    },
  });
};
function* getEntryFileGenerator({ payload }) {
  try {
    const {
      data: { readEntryFile },
    } = yield call(GET_ENTRY_FILE_API, payload);
    yield put(getEntryFileSuccess(readEntryFile));
  } catch (e) {
    handleErrorMessage(e, 'Get Entry File Error', false);
    getEntryFileError();
  }
}

export function* watchGetOrganization() {
  yield takeEvery(GET_ORGANIZATION, getOrganizationGenerator);
}
const GET_ORGANIZATION_API = async (args) => {
  return apolloClient.query({
    query: GET_ORGANIZATION_QUERY,
    variables: {
      uniqueId: args?.unique_id,
    },
  });
};
function* getOrganizationGenerator({ payload }) {
  try {
    const {
      data: { getOrganization },
    } = yield call(GET_ORGANIZATION_API, payload.args);
    yield put(getOrganizationSuccess(getOrganization));
  } catch (e) {
    handleErrorMessage(e, 'Get Organization Error', false);
    getOrganizationError();
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchReadEntry),
    fork(watchVerifyReCaptcha),
    fork(watchCreateReaderLog),
    fork(watchResetPassword),
    fork(watchSendRecoveryCode),
    fork(watchVerifyRecoveryCode),
    fork(watchRegisterUser),
    fork(watchVerifyUser),
    fork(watchGetEntryFile),
    fork(watchCreateIssueReport),
    fork(watchGetOrganization),
  ]);
}
