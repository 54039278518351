const { gql } = require('@apollo/client');

// TODO: Verify if everything here is in the proper file (non-auth section?)

const CREATE_ENTRY_QUERY = gql(`
  mutation(
    $nickname: String!, 
    $salt: String!, 
    $url: String!, 
    $jsonPayload: String!, 
    $template: String!, 
    $expiresAt: String!, 
    $encryptionVersion: Int!, 
    $hashVersion: Int!, 
    $compoundKeyVersion: Int!, 
    $metadata: String!,
    $entryFiles: [EntryFileInput!]){
      createEntry(
        nickname: $nickname, 
        salt: $salt, 
        url: $url, 
        json_payload: $jsonPayload, 
        template: $template, 
        expires_at: $expiresAt, 
        encryption_version: $encryptionVersion, 
        hash_version: $hashVersion, 
        compound_key_version: $compoundKeyVersion, 
        entry_files: $entryFiles, 
        metadata: $metadata
      ) {
        id, nickname, url, salt, json_payload, expires_at, metadata
      }
    }
`);

const GET_ENTRY_FILE_URL_QUERY = gql(`
  query{
    getEntryFileUrl {
      url, filename
    }
  }
`);

const ENTRY_ISSUE_REPORTS_QUERY = gql(`
  query($entryId: Int, $organizationId: Int){
    entryIssueReports(entryId: $entryId, organizationId: $organizationId) {
      id, name, email, type, message, location, ip, created_at
    }
  }
`);

const ENTRY_READER_LOGS_QUERY = gql(`
  query($entryId: Int!){
    entryReaderLogs(entryId: $entryId) {
      id, created_at, location, result
    }
  }
`);

const BULK_DELETE_ENTRIES_QUERY = gql(`
  mutation($ids: [ID]!){
    bulkDeleteEntries(ids: $ids) {
      status, message
    }
  }
`);

const BULK_RESTORE_ENTRIES_QUERY = gql(`
  mutation($ids: [ID]!){
    bulkRestoreEntries(ids: $ids) {
      status, message
    }
  }
`);

const BULK_POSTPONE_EXPIRATION_ENTRIES_QUERY = gql(`
  mutation($ids: [ID]!, $expiresAt: String!){
    bulkPostponeEntryExpiration(ids: $ids, expires_at: $expiresAt) {
      message, status
    }
  }
`);

module.exports = {
  CREATE_ENTRY_QUERY,
  GET_ENTRY_FILE_URL_QUERY,
  ENTRY_ISSUE_REPORTS_QUERY,
  ENTRY_READER_LOGS_QUERY,
  BULK_DELETE_ENTRIES_QUERY,
  BULK_RESTORE_ENTRIES_QUERY,
  BULK_POSTPONE_EXPIRATION_ENTRIES_QUERY,
};
