import {
  ADD_TASK,
  ADD_TASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DOWNLOAD_TASK,
  DOWNLOAD_TASK_CLEAR,
  DOWNLOAD_TASK_SUCCESS,
  GET_TASKS,
  GET_TASKS_ADMIN,
  GET_TASKS_SUCCESS,
  GET_TASK_URL,
  GET_TASK_URL_SUCCESS,
  UPDATE_TASK,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_SUCCESS,
} from 'redux/contants';

export const getTasks = (args) => ({
  type: GET_TASKS,
  payload: { args },
});

export const getTasksAdmin = (args) => ({
  type: GET_TASKS_ADMIN,
  payload: { args },
});

export const getTaskUrl = (args) => ({
  type: GET_TASK_URL,
  payload: { args },
});

export const getTaskUrlSuccess = (taskUrlResponse) => ({
  type: GET_TASK_URL_SUCCESS,
  payload: taskUrlResponse,
});

export const downloadTask = (args) => ({
  type: DOWNLOAD_TASK,
  payload: { args },
});

export const downloadTaskSuccess = (downloadedTask) => ({
  type: DOWNLOAD_TASK_SUCCESS,
  payload: downloadedTask,
});

export const downloadTaskClear = () => ({
  type: DOWNLOAD_TASK_CLEAR,
  payload: {},
});

export const getTasksSuccess = (tasks) => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
});

export const addTask = (args) => ({
  type: ADD_TASK,
  payload: { args },
});

export const addTaskSuccess = (tasks) => ({
  type: ADD_TASK_SUCCESS,
  payload: tasks,
});

export const updateTask = (args) => ({
  type: UPDATE_TASK,
  payload: { args },
});

export const updateTaskSuccess = (tasks) => ({
  type: UPDATE_TASK_SUCCESS,
  payload: tasks,
});

export const deleteTask = (args) => ({
  type: DELETE_TASK,
  payload: { args },
});

export const deleteTaskSuccess = (tasks) => ({
  type: DELETE_TASK_SUCCESS,
  payload: tasks,
});

export const updateTaskStatus = (args) => ({
  type: UPDATE_TASK_STATUS,
  payload: { args },
});

export const updateTaskStatusSuccess = (tasks) => ({
  type: UPDATE_TASK_STATUS_SUCCESS,
  payload: tasks,
});
