/* eslint-disable import/prefer-default-export */

import {
  BULK_DELETE_ENTRIES,
  BULK_DELETE_ENTRIES_ERROR,
  BULK_DELETE_ENTRIES_SUCCESS,
  BULK_POSTPONE_EXPIRATION_ENTRIES,
  BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR,
  BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS,
  BULK_RESTORE_ENTRIES,
  BULK_RESTORE_ENTRIES_ERROR,
  BULK_RESTORE_ENTRIES_SUCCESS,
  CLEAR_ENTRY,
  CREATE_ENTRY,
  CREATE_ENTRY_ERROR,
  CREATE_ENTRY_SUCCESS,
  ENTRY_ISSUE_REPORTS,
  ENTRY_ISSUE_REPORTS_CLEAR,
  ENTRY_ISSUE_REPORTS_SUCCESS,
  ENTRY_READER_LOGS,
  ENTRY_READER_LOGS_CLEAR,
  ENTRY_READER_LOGS_SUCCESS,
  GET_ENTRY_FILE_URL,
  GET_ENTRY_FILE_URL_SUCCESS,
} from 'redux/contants';

export const createEntry = (entry, history) => ({
  type: CREATE_ENTRY,
  payload: { entry, history },
});
export const createEntrySuccess = (entry) => ({
  type: CREATE_ENTRY_SUCCESS,
  payload: entry,
});
export const createEntryError = () => ({
  type: CREATE_ENTRY_ERROR,
  payload: {},
});

export const getEntryFileUrl = () => ({
  type: GET_ENTRY_FILE_URL,
  payload: {},
});
export const getEntryFileUrlSuccess = (entryFile) => ({
  type: GET_ENTRY_FILE_URL_SUCCESS,
  payload: entryFile,
});

export const getEntryIssueReports = (args) => ({
  type: ENTRY_ISSUE_REPORTS,
  payload: { args },
});
export const getEntryIssueReportsSuccess = (issueReports) => ({
  type: ENTRY_ISSUE_REPORTS_SUCCESS,
  payload: issueReports,
});
export const clearEntryIssueReports = () => ({
  type: ENTRY_ISSUE_REPORTS_CLEAR,
  payload: {},
});

export const getEntryReaderLogs = (args) => ({
  type: ENTRY_READER_LOGS,
  payload: { args },
});
export const getEntryReaderLogsSuccess = (readerLogs) => ({
  type: ENTRY_READER_LOGS_SUCCESS,
  payload: readerLogs,
});
export const clearEntryReaderLogs = () => ({
  type: ENTRY_READER_LOGS_CLEAR,
  payload: {},
});

export const bulkDeleteEntries = (args) => ({
  type: BULK_DELETE_ENTRIES,
  payload: { args },
});
export const bulkDeleteEntriesSuccess = () => ({
  type: BULK_DELETE_ENTRIES_SUCCESS,
  payload: {},
});
export const bulkDeleteEntriesError = () => ({
  type: BULK_DELETE_ENTRIES_ERROR,
  payload: {},
});

export const bulkRestoreEntries = (args) => ({
  type: BULK_RESTORE_ENTRIES,
  payload: { args },
});
export const bulkRestoreEntriesSuccess = () => ({
  type: BULK_RESTORE_ENTRIES_SUCCESS,
  payload: {},
});
export const bulkRestoreEntriesError = () => ({
  type: BULK_RESTORE_ENTRIES_ERROR,
  payload: {},
});

export const bulkPostponeExpirationEntries = (args) => ({
  type: BULK_POSTPONE_EXPIRATION_ENTRIES,
  payload: { args },
});
export const bulkPostponeExpirationEntriesSuccess = () => ({
  type: BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS,
  payload: {},
});
export const bulkPostponeExpirationEntriesError = () => ({
  type: BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR,
  payload: {},
});

export const clearEntry = () => ({
  type: CLEAR_ENTRY,
  payload: {},
});
