import {
  BULK_DELETE_ENTRIES,
  BULK_DELETE_ENTRIES_ERROR,
  BULK_DELETE_ENTRIES_SUCCESS,
  BULK_POSTPONE_EXPIRATION_ENTRIES,
  BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR,
  BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS,
  BULK_RESTORE_ENTRIES,
  BULK_RESTORE_ENTRIES_ERROR,
  BULK_RESTORE_ENTRIES_SUCCESS,
  CLEAR_ENTRY,
  CREATE_ENTRY,
  CREATE_ENTRY_ERROR,
  CREATE_ENTRY_SUCCESS,
  ENTRY_ISSUE_REPORTS,
  ENTRY_ISSUE_REPORTS_CLEAR,
  ENTRY_ISSUE_REPORTS_SUCCESS,
  ENTRY_READER_LOGS,
  ENTRY_READER_LOGS_CLEAR,
  ENTRY_READER_LOGS_SUCCESS,
  GET_ENTRY_FILE_URL,
  GET_ENTRY_FILE_URL_SUCCESS,
} from 'redux/contants';

const INIT_STATE = {
  entryFileUrl: null,
  entryIssueReports: null,
  entryReaderLogs: null,
  entry: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_ENTRY:
      return { ...state, loading: true, error: '', entry: null };
    case CLEAR_ENTRY:
      return {
        ...state,
        loading: false,
        error: '',
        entry: null,
        entryFileUrl: null,
      };
    case CREATE_ENTRY_SUCCESS:
      return { ...state, loading: false, error: '', entry: action.payload };
    case CREATE_ENTRY_ERROR:
      return { ...state, loading: false, error: '' };
    case GET_ENTRY_FILE_URL:
      return { ...state, loading: true, error: '', entryFileUrl: null };
    case GET_ENTRY_FILE_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        entryFileUrl: action.payload,
      };
    case ENTRY_ISSUE_REPORTS:
      return { ...state, loading: true, error: '', entryIssueReports: null };
    case ENTRY_ISSUE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        entryIssueReports: action.payload,
      };
    case ENTRY_ISSUE_REPORTS_CLEAR:
      return { ...state, loading: false, error: '', entryIssueReports: null };
    case ENTRY_READER_LOGS:
      return { ...state, loading: true, error: '', entryReaderLogs: null };
    case ENTRY_READER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        entryReaderLogs: action.payload,
      };
    case ENTRY_READER_LOGS_CLEAR:
      return { ...state, loading: false, error: '', entryReaderLogs: null };
    case BULK_DELETE_ENTRIES:
      return { ...state, loading: true, error: '' };
    case BULK_DELETE_ENTRIES_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_DELETE_ENTRIES_ERROR:
      return { ...state, loading: false, error: '' };
    case BULK_RESTORE_ENTRIES:
      return { ...state, loading: true, error: '' };
    case BULK_RESTORE_ENTRIES_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_RESTORE_ENTRIES_ERROR:
      return { ...state, loading: false, error: '' };
    case BULK_POSTPONE_EXPIRATION_ENTRIES:
      return { ...state, loading: true, error: '' };
    case BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR:
      return { ...state, loading: false, error: '' };
    default:
      return { ...state };
  }
};
