const { gql } = require('@apollo/client');

const ALL_PRICING_PLANS_QUERY = gql(`
  query{
    allPricingPlans {
      id, title, description, specifications, price, specifications, type, deleted_at, stripe_price_id, number_of_entries, entry_expiration_duration, monthly_entries_access, entry_renewal
    }
  }
`);
const GET_ALL_SUBSCRIPTIONS_QUERY = gql(`
  query($filterValue: String!){
    allSubscriptions(filter: $filterValue) {
      id, start_at, end_at, created_at, stripe_invoice_id, stripe_subscription_id, deleted_at, pricing_plan {
        title, price, type
      }, user {
        id, name
      }
    }
  }
`);
const GET_PRICING_PLAN_QUERY = gql(`
  query($getPricingPlanId: Int!){
    getPricingPlan(id: $getPricingPlanId) {
      id, title, description, specifications, price, specifications, type, deleted_at, stripe_price_id, number_of_entries, entry_expiration_duration, monthly_entries_access, entry_renewal
    }
  }
`);
const ADD_PRICING_PLAN_QUERY = gql(`
  mutation($title: String!, $description: String!, $price: Float!, $specifications: String!, $type: String!, $numberOfEntries: Int!, $monthlyEntriesAccess: Int!, $entryRenewal: String!, $entryExpirationDuration: Int!){
    createPricingPlan(title: $title, description: $description, price: $price, specifications: $specifications, type: $type, number_of_entries: $numberOfEntries, monthly_entries_access: $monthlyEntriesAccess, entry_renewal: $entryRenewal, entry_expiration_duration: $entryExpirationDuration) {
      id, title, description, specifications, price, specifications, type, deleted_at, stripe_price_id
    }
  }
`);
const TOGGLE_PRICING_PLAN_QUERY = gql(`
  mutation($togglePricingPlanId: Int!){
    togglePricingPlan(id: $togglePricingPlanId) {
      id, title, description, specifications, price, specifications, type, deleted_at, stripe_price_id
    }
  }
`);
const EDIT_PRICING_PLAN_QUERY = gql(`
  mutation($updatePricingPlanId: Int!, $title: String!, $description: String!, $specifications: String!, $numberOfEntries: Int!, $monthlyEntriesAccess: Int!, $entryRenewal: String!, $entryExpirationDuration: Int!){
    updatePricingPlan(id: $updatePricingPlanId, title: $title, description: $description, specifications: $specifications, number_of_entries: $numberOfEntries, monthly_entries_access: $monthlyEntriesAccess, entry_renewal: $entryRenewal, entry_expiration_duration: $entryExpirationDuration) {
      id, title, description, specifications, price, specifications, type, deleted_at, stripe_price_id
    }
  }
`);
const SUBSCRIBE_PRICING_PLAN_QUERY = gql(`
  mutation($name: String!, $email: String!, $secondaryEmail: String!, $token: String!, $pricingPlanId: Int!){
    subscribePricingPlan(name: $name, email: $email, token: $token, pricing_plan_id: $pricingPlanId, secondary_email: $secondaryEmail) {
      message, status,
      user{
        id, secondary_email, name, email, is_email_verified, deleted_at, admin_guid, stripe_customer_id
      }
    }
  }
`);
const GET_SUBSCRIPTION_QUERY = gql(`
  query{
    getSubscription {
      id, stripe_invoice_id, stripe_subscription_id, start_at, end_at, deleted_at, created_at, pricing_plan {
        title, description, id, price, type, number_of_entries, monthly_entries_access, entry_expiration_duration, entry_renewal
      }, stripe_customer {
        email, payment_method {
          last4, brand
        }
      }
    }
  }
`);
const CANCEL_SUBSCRIPTION_QUERY = gql(`
  mutation{
    cancelSubscription {
      message, status
    }
  }
`);
const GET_USER_TRANSACTION_HISTORY_QUERY = gql(`
  query($stripeSubscriptionId: String!){
    getCustomerTransationHistory(stripe_subscription_id: $stripeSubscriptionId) {
      id, invoice_pdf, amount_due, amount_paid, account_country, account_name, status, created, paid, brand, last4
    }
  }
`);

module.exports = {
  ALL_PRICING_PLANS_QUERY,
  ADD_PRICING_PLAN_QUERY,
  TOGGLE_PRICING_PLAN_QUERY,
  EDIT_PRICING_PLAN_QUERY,
  GET_PRICING_PLAN_QUERY,
  SUBSCRIBE_PRICING_PLAN_QUERY,
  GET_SUBSCRIPTION_QUERY,
  CANCEL_SUBSCRIPTION_QUERY,
  GET_ALL_SUBSCRIPTIONS_QUERY,
  GET_USER_TRANSACTION_HISTORY_QUERY,
};
