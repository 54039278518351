const { gql } = require('@apollo/client');

const ADMIN_SUMMARY_QUERY = gql(`
  query{
    adminSummary {
      activeEntries, expiredEntries, deletedEntries, 
      deletedPublishers, pendingPublishers, verifiedPublishers, organizations,
      activeSubscriptions, canceledSubscriptions, pendingTasks, approvedTasks, rejectedTasks
    }
  }`);

const TEMPLATES_USAGE_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    templatesUsageReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      template, totalCount
    }
  }`);

const READER_LOGS_REPORT_QUERY = gql(`
  query($createdAtEnd: String!, $createdAtStart: String!){
    readerLogsReport(createdAtEnd: $createdAtEnd, createdAtStart: $createdAtStart) {
      id, name, nickname, total
    }
  }`);

const PUBLISHERS_REGISTRATION_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    publishersRegistrationReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      name, secondary_email, created_at, deleted_at
    }
  }`);

const ENTRIES_CREATION_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    entriesCreationReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      nickname, name, created_at, expires_at, template, deleted_at
    }
  }`);

const ALL_PUBLISHERS_QUERY = gql(`
  query($page: Int, $limit: Int, $search: String, $orderDirection: OrderDirection, $orderByField: OrderByFieldUsers, $paranoid: Boolean!){
    allUsers(page: $page, limit: $limit, search: $search, orderDirection: $orderDirection, orderByField: $orderByField, paranoid: $paranoid) {
      allPages, page, count, rows {
        id, name, created_at, deleted_at, entries {
          id, nickname
        }, organization {
          id, name
        }
      }
    }
  }`);

const DELETE_PUBLISHER_QUERY = gql(`
  mutation($deleteUserByIdId: Int!){
    deleteUserById(id: $deleteUserByIdId) {
      message, status
    }
  }`);

const RESTORE_PUBLISHER_QUERY = gql(`
  mutation($restoreUserByIdId: Int!){
    restoreUserById(id: $restoreUserByIdId) {
      message, status
    }
  }`);

const CREATE_ORGANIZATION_QUERY = gql(`
  mutation($name: String!, $details: String!){
    createOrganization(name: $name, details: $details) {
      status, message
    }
  }`);

const ALL_ORGANIZATIONS_QUERY = gql(`
  query($page: Int!, $limit: Int!, $search: String!, $orderByField: OrderByFieldOrganizations!, $orderDirection: OrderDirection!){
    allOrganizations(page: $page, limit: $limit, search: $search, orderByField: $orderByField, orderDirection: $orderDirection) {
      allPages, page, count, rows {
        id, name, details, created_at, unique_id, users {
          name, id
        }
      },
    }
  }`);

const DELETE_ORGANIZATION_QUERY = gql(`
  mutation($deleteOrganizationId: ID!){
    deleteOrganization(id: $deleteOrganizationId) {
      message, status
    }
  }`);

const UPDATE_ORGANIZATION_QUERY = gql(`
  mutation($updateOrganizationId: ID!, $name: String!, $details: String!){
    updateOrganization(id: $updateOrganizationId, name: $name, details: $details) {
      status, message
    }
  }`);

const ASSOCIATE_ORGANIZATION_QUERY = gql(`
  mutation($associateOrganizationId: Int!, $organizationId: Int!){
    associateOrganization(id: $associateOrganizationId, organization_id: $organizationId) {
      message, status
    }
  }`);

const DISSOCIATE_ORGANIZATION_QUERY = gql(`
  mutation($dissociateOrganizationId: Int!){
    dissociateOrganization(id: $dissociateOrganizationId) {
      message, status    
    }
  }`);

module.exports = {
  ADMIN_SUMMARY_QUERY,
  ALL_PUBLISHERS_QUERY,
  DELETE_PUBLISHER_QUERY,
  RESTORE_PUBLISHER_QUERY,
  TEMPLATES_USAGE_REPORT_QUERY,
  READER_LOGS_REPORT_QUERY,
  PUBLISHERS_REGISTRATION_REPORT_QUERY,
  ENTRIES_CREATION_REPORT_QUERY,
  CREATE_ORGANIZATION_QUERY,
  ALL_ORGANIZATIONS_QUERY,
  DELETE_ORGANIZATION_QUERY,
  UPDATE_ORGANIZATION_QUERY,
  ASSOCIATE_ORGANIZATION_QUERY,
  DISSOCIATE_ORGANIZATION_QUERY,
};
