import {
  ADD_TASK,
  ADD_TASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DOWNLOAD_TASK,
  DOWNLOAD_TASK_CLEAR,
  DOWNLOAD_TASK_SUCCESS,
  GET_TASKS,
  GET_TASKS_ADMIN,
  GET_TASKS_SUCCESS,
  GET_TASK_URL,
  GET_TASK_URL_SUCCESS,
  UPDATE_TASK,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_SUCCESS,
} from 'redux/contants';

const INIT_STATE = {
  loading: false,
  error: null,
  allTasks: null,
  taskUrl: null,
  downloadedTask: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASKS:
      return { ...state, loading: true, error: null };
    case GET_TASKS_ADMIN:
      return { ...state, loading: true, error: null };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allTasks: action.payload,
      };
    case DOWNLOAD_TASK:
      return { ...state, loading: true, error: null };
    case DOWNLOAD_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        downloadedTask: action.payload,
      };
    case DOWNLOAD_TASK_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        downloadedTask: null,
      };
    case ADD_TASK:
      return { ...state, loading: true, error: null };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allTasks: action.payload,
      };
    case UPDATE_TASK:
      return { ...state, loading: true, error: null };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allTasks: action.payload,
      };
    case GET_TASK_URL:
      return { ...state, loading: true, error: null };
    case GET_TASK_URL_SUCCESS:
      return {
        ...state,
        error: null,
        taskUrl: action.payload,
      };
    case DELETE_TASK:
      return { ...state, loading: true, error: null };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allTasks: action.payload,
      };
    case UPDATE_TASK_STATUS:
      return { ...state, loading: true, error: null };
    case UPDATE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allTasks: action.payload,
      };
    default:
      return { ...state };
  }
};
