/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCurrentUser, handleErrorMessage, handleSuccessMessage, setCurrentUser, updateCurrentUser } from 'helpers/Utils';
import {
  allEntriesSuccess,
  deleteEntrySuccess,
  deleteUserSuccess,
  entriesSummarySuccess,
  getNotificationsSuccess,
  markNotificationsAsSeenSuccess,
  resendVerificationEmailError,
  resendVerificationEmailSuccess,
  restoreEntrySuccess,
  updatePasswordError,
  updatePasswordSuccess,
  updatePersonalInformationSuccess,
  userSummarySuccess
} from 'redux/actions';
import {
  ALL_ENTRIES,
  DELETE_ENTRY,
  DELETE_ENTRY_SUCCESS,
  DELETE_USER,
  ENTRIES_SUMMARY,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_SEEN,
  POSTPONE_ENTRY_EXPIRATION,
  RESEND_VERIFICATION_EMAIL,
  RESTORE_ENTRY,
  UPDATE_PASSWORD,
  UPDATE_PERSONAL_INFORMATION,
  USER_SUMMARY
} from 'redux/contants';
import {
  ALL_ENTRIES_QUERY,
  DELETE_ENTRY_QUERY,
  DELETE_USER_QUERY,
  ENTRIES_SUMMARY_QUERY,
  GET_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_SEEN_QUERY,
  POSTPONE_ENTRY_EXPIRATION_QUERY,
  RESEND_VERIFICATION_EMAIL_QUERY,
  RESTORE_ENTRY_QUERY,
  UPDATE_PASSWORD_QUERY,
  UPDATE_PERSONAL_INFORMATION_QUERY,
  USER_SUMMARY_QUERY
} from './queries';

export function* watchUserSummary() {
  yield takeLatest(USER_SUMMARY, userSummaryGenerator);
}
const USER_SUMMARY_API = async () => {
  return apolloClient.query({
    query: USER_SUMMARY_QUERY,
    variables: {}
  });
}
function* userSummaryGenerator() {
  try {
    const { data: { userSummary } } = yield call(USER_SUMMARY_API);
    yield put(userSummarySuccess(userSummary));
  } catch (e) {
    handleErrorMessage(e, 'User Summary Error', null);
  }
}

export function* watchGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsGenerator);
}
const GET_NOTIFICATIONS_API = async () => {
  return apolloClient.query({
    query: GET_NOTIFICATIONS_QUERY,
    variables: {}
  });
}
function* getNotificationsGenerator() {
  try {
    const { data: { getNotifications } } = yield call(GET_NOTIFICATIONS_API);
    yield put(getNotificationsSuccess(getNotifications));
  } catch (e) {
    handleErrorMessage(e, 'Get Notifications Error', null);
  }
}

export function* watchMarkNotificationsAsSeen() {
  yield takeLatest(MARK_NOTIFICATIONS_AS_SEEN, markNotificationsAsSeenGenerator);
}
const MARK_NOTIFICATIONS_AS_SEEN_API = async () => {
  return apolloClient.query({
    query: MARK_NOTIFICATIONS_AS_SEEN_QUERY,
    variables: {}
  });
}
function* markNotificationsAsSeenGenerator() {
  try {
    const { data: { markNotificationsAsSeen } } = yield call(MARK_NOTIFICATIONS_AS_SEEN_API);
    yield put(markNotificationsAsSeenSuccess(markNotificationsAsSeen));
  } catch (e) {
    handleErrorMessage(e, 'Mark Notifications As Seen Error', null);
  }
}

export function* watchUpdatePersonalInformation() {
  yield takeEvery(UPDATE_PERSONAL_INFORMATION, updatePersonalInformationGenerator);
}
export const UPDATE_PERSONAL_INFORMATION_API = async ( user ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PERSONAL_INFORMATION_QUERY,
    variables: { name: user.name, secondaryEmail: user.secondary_email }
  });
}
export function* updatePersonalInformationGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const { data: { updatePersonalInformation }} = yield call(UPDATE_PERSONAL_INFORMATION_API, user);
    yield put(updatePersonalInformationSuccess(updatePersonalInformation));
    handleSuccessMessage('Your personal information has been updated.', 'Personal Information Updated');
    updateCurrentUser(updatePersonalInformation);
  } catch (e) {
    handleErrorMessage(e, 'Update Personal Information Error', history);
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePasswordGenerator);
}
export const UPDATE_PASSWORD_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PASSWORD_QUERY,
    variables: { newPassword: args.new_password, currentPassword: args.current_password }
  });
}
export function* updatePasswordGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { updatePassword }} = yield call(UPDATE_PASSWORD_API, args);
    handleSuccessMessage(updatePassword, 'Password Updated');
    yield put(updatePasswordSuccess());
  } catch (e) {
    yield put(updatePasswordError());
    handleErrorMessage(e, 'Update Password Error', history);
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserGenerator);
}
const DELETE_USER_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: DELETE_USER_QUERY,
    variables: { password: args.password }
  });
}
function* deleteUserGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { deleteUser }} = yield call(DELETE_USER_API, args);
    handleSuccessMessage(deleteUser, 'Account Deleted');
    yield put(deleteUserSuccess());
    setCurrentUser(null);
    history.push('/user/login');
  } catch (e) {
    yield put(deleteUserSuccess());
    handleErrorMessage(e, 'Delete Account Error', history);
  }
}

export function* watchAllEntries() {
  yield takeEvery(ALL_ENTRIES, allEntriesGenerator);
}
const ALL_ENTRIES_API = async ( args ) => {
  return apolloClient.query({
    query: ALL_ENTRIES_QUERY,
    variables: { userId: args.userId, limit: args?.limit, page: args?.page, search: args?.search, orderByField: args?.orderByField, orderDirection: args?.orderDirection, status: args?.status }
  });
}
function* allEntriesGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { allEntries }} = yield call(ALL_ENTRIES_API, args);
    yield put(allEntriesSuccess(allEntries));
  } catch (e) {
    handleErrorMessage(e, 'All Entries Error', null);
  }
}

export function* watchResendVerificationEmail() {
  yield takeEvery(RESEND_VERIFICATION_EMAIL, resendVerificationEmailGenerator);
}
const RESEND_VERIFICATION_EMAIL_API = async ( args ) => {
  return apolloClient.query({
    query: RESEND_VERIFICATION_EMAIL_QUERY,
    variables: { email: args?.email }
  });
}
function* resendVerificationEmailGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { resendVerificationEmail }} = yield call(RESEND_VERIFICATION_EMAIL_API, args);
    handleSuccessMessage(resendVerificationEmail, 'Verification Email Resent');
    yield put(resendVerificationEmailSuccess(resendVerificationEmail));
  } catch (e) {
    handleErrorMessage(e, 'Resend Verification Email Error', null);
    yield put(resendVerificationEmailError());
  }
}

export function* watchEntriesSummary() {
  yield takeLatest(ENTRIES_SUMMARY, entriesSummaryGenerator);
}
const ENTRIES_SUMMARY_API = async () => {
  return apolloClient.query({
    query: ENTRIES_SUMMARY_QUERY,
    variables: {}
  });
}
function* entriesSummaryGenerator() {
  try {
    const { data: { entriesSummary }} = yield call(ENTRIES_SUMMARY_API);
    yield put(entriesSummarySuccess(entriesSummary));
  } catch (e) {
    handleErrorMessage(e, 'Entries Summary Error', null);
  }
}

export function* watchDeleteEntry() {
  yield takeEvery(DELETE_ENTRY, deleteEntryGenerator);
}
const DELETE_ENTRY_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_ENTRY_QUERY,
    variables: {deleteEntryId: args.id}
  });
}
function* deleteEntryGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { deleteEntry }} = yield call(DELETE_ENTRY_API, args);
    handleSuccessMessage(deleteEntry, 'Entry Deleted');
    yield put(deleteEntrySuccess());
  } catch (e) {
    handleErrorMessage(e, 'Delete Entry Error', null);
  }
}

export function* watchRestoreEntry() {
  yield takeEvery(RESTORE_ENTRY, restoreEntryGenerator);
}
const RESTORE_ENTRY_API = async (args) => {
  return apolloClient.mutate({
    mutation: RESTORE_ENTRY_QUERY,
    variables: {restoreEntryId: args.id}
  });
}
function* restoreEntryGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { restoreEntry }} = yield call(RESTORE_ENTRY_API, args);
    handleSuccessMessage(restoreEntry, 'Entry Restored');
    yield put(restoreEntrySuccess());
  } catch (e) {
    handleErrorMessage(e, 'Restore Entry Error', null);
  }
}

export function* watchPostponeEntryExpiration() {
  yield takeEvery(POSTPONE_ENTRY_EXPIRATION, postponeEntryExpirationGenerator);
}
const POSTPONE_ENTRY_EXPIRATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: POSTPONE_ENTRY_EXPIRATION_QUERY,
    variables: {postponeEntryExpirationId: args.id, expiresAt: args.expires_at, isFuture: args?.is_future}
  });
}
function* postponeEntryExpirationGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { postponeEntryExpiration }} = yield call(POSTPONE_ENTRY_EXPIRATION_API, args);
    handleSuccessMessage(postponeEntryExpiration, args.is_future ? 'Entry Expiration Postponed' : 'Entry Marked as Expired');
  } catch (e) {
    handleErrorMessage(e, 'Postpone Entry Expiration Error', null);
  }
}

export default function* rootSaga() {
  yield all([
    // Sorted from most common to least common
    // User effects
    fork(watchUserSummary),
    fork(watchUpdatePersonalInformation),
    fork(watchUpdatePassword),
    fork(watchResendVerificationEmail),
    fork(watchDeleteUser),
    // Entry effects
    fork(watchEntriesSummary),
    fork(watchAllEntries),
    fork(watchPostponeEntryExpiration),
    fork(watchDeleteEntry),
    fork(watchRestoreEntry),
    // Notifications Effects
    fork(watchGetNotifications),
    fork(watchMarkNotificationsAsSeen),
  ]);
  
}
