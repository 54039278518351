/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  ADD_PRICING_PLAN,
  ALL_PRICING_PLANS,
  CANCEL_SUBSCRIPTION,
  EDIT_PRICING_PLAN,
  GET_ALL_SUBSCRIPTIONS,
  GET_PRICING_PLAN,
  GET_SUBSCRIPTION,
  GET_USER_TRANSACTION_HISTORY,
  SUBSCRIBE_PRICING_PLAN,
  TOGGLE_PRICING_PLAN,
} from 'redux/contants';
import { apolloClient } from 'redux/sagas';
import {
  handleErrorMessage,
  handleSuccessMessage,
  updateCurrentUser,
} from 'helpers/Utils';
import { userRoot } from 'constants/defaultValues';
import {
  ADD_PRICING_PLAN_QUERY,
  ALL_PRICING_PLANS_QUERY,
  CANCEL_SUBSCRIPTION_QUERY,
  EDIT_PRICING_PLAN_QUERY,
  GET_ALL_SUBSCRIPTIONS_QUERY,
  GET_PRICING_PLAN_QUERY,
  GET_SUBSCRIPTION_QUERY,
  GET_USER_TRANSACTION_HISTORY_QUERY,
  SUBSCRIBE_PRICING_PLAN_QUERY,
  TOGGLE_PRICING_PLAN_QUERY,
} from './queries';
import {
  addPricingPlanSuccess,
  allPricingPlansSuccess,
  togglePricingPlanSuccess,
  editPricingPlanSuccess,
  getPricingPlanError,
  getPricingPlanSuccess,
  subscribePricingPlanError,
  subscribePricingPlanSuccess,
  getSubscriptionSuccess,
  cancelSubscriptionSuccess,
  getAllSubscriptionsSuccess,
  getTransactionHistoriesSuccess,
  addPricingPlanError,
} from './actions';

export function* watchAllPricingPlans() {
  yield takeLatest(ALL_PRICING_PLANS, allPricingPlansGenerator);
}
const ALL_PRICING_PLANS_API = async () => {
  return apolloClient.query({
    query: ALL_PRICING_PLANS_QUERY,
    variables: {},
  });
};
function* allPricingPlansGenerator() {
  try {
    const {
      data: { allPricingPlans },
    } = yield call(ALL_PRICING_PLANS_API);
    yield put(allPricingPlansSuccess(allPricingPlans));
  } catch (e) {
    handleErrorMessage(e, 'All Pricing Plans Error', null);
  }
}

export function* watchGetAllSubscriptions() {
  yield takeLatest(GET_ALL_SUBSCRIPTIONS, getAllSubscriptionsGenerator);
}
const GET_ALL_SUBSCRIPTIONS_API = async (args) => {
  return apolloClient.query({
    query: GET_ALL_SUBSCRIPTIONS_QUERY,
    variables: {
      filterValue: args,
    },
  });
};
function* getAllSubscriptionsGenerator({ payload }) {
  try {
    const {
      data: { allSubscriptions },
    } = yield call(GET_ALL_SUBSCRIPTIONS_API, payload?.args);
    yield put(getAllSubscriptionsSuccess(allSubscriptions));
  } catch (e) {
    handleErrorMessage(e, 'Get All Subscriptions Error', null);
  }
}

export function* watchGetPricingPlan() {
  yield takeLatest(GET_PRICING_PLAN, getPricingPlanGenerator);
}
const GET_PRICING_PLAN_API = async (args) => {
  return apolloClient.query({
    query: GET_PRICING_PLAN_QUERY,
    variables: {
      getPricingPlanId: args?.id,
    },
  });
};
function* getPricingPlanGenerator({ payload }) {
  try {
    const {
      data: { getPricingPlan },
    } = yield call(GET_PRICING_PLAN_API, payload?.args);
    yield put(getPricingPlanSuccess(getPricingPlan));
  } catch (e) {
    handleErrorMessage(e, 'Get Pricing Plans Error', null);
    yield put(getPricingPlanError());
  }
}

export function* watchAddPricingPlan() {
  yield takeLatest(ADD_PRICING_PLAN, addPricingPlanGenerator);
}
const ADD_PRICING_PLAN_API = async (args) => {
  return apolloClient.query({
    query: ADD_PRICING_PLAN_QUERY,
    variables: {
      title: args?.title,
      description: args?.description,
      specifications: args?.specifications,
      price: args?.price,
      type: args?.type,
      numberOfEntries: args?.number_of_entries,
      entryRenewal: args?.entry_renewal,
      entryExpirationDuration: args?.entry_expiration_duration,
      monthlyEntriesAccess: args?.monthly_entries_access,
    },
  });
};
function* addPricingPlanGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const {
      data: { createPricingPlan },
    } = yield call(ADD_PRICING_PLAN_API, args);
    yield put(addPricingPlanSuccess(createPricingPlan));
    handleSuccessMessage(
      'Pricing plan has been created successfully!',
      'Pricing Plan Created'
    );
    history.push('/admin/pricing-plans/view');
  } catch (e) {
    handleErrorMessage(e, 'Add Pricing Plan Error', null);
    yield put(addPricingPlanError());
  }
}

export function* watchEditPricingPlan() {
  yield takeLatest(EDIT_PRICING_PLAN, editPricingPlanGenerator);
}
const EDIT_PRICING_PLAN_API = async (args) => {
  return apolloClient.query({
    query: EDIT_PRICING_PLAN_QUERY,
    variables: {
      updatePricingPlanId: args?.id,
      title: args?.title,
      description: args?.description,
      specifications: args?.specifications,
      numberOfEntries: args?.number_of_entries,
      entryRenewal: args?.entry_renewal,
      entryExpirationDuration: args?.entry_expiration_duration,
      monthlyEntriesAccess: args?.monthly_entries_access,
    },
  });
};
function* editPricingPlanGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const {
      data: { updatePricingPlan },
    } = yield call(EDIT_PRICING_PLAN_API, args);
    yield put(editPricingPlanSuccess(updatePricingPlan));
    handleSuccessMessage(
      'Pricing plan has been updated successfully!',
      'Pricing Plan Updated'
    );
    history.push('/admin/pricing-plans/view');
  } catch (e) {
    handleErrorMessage(e, 'Edit Pricing Plan Error', null);
  }
}

export function* watchTogglePricingPlan() {
  yield takeLatest(TOGGLE_PRICING_PLAN, togglePricingPlanGenerator);
}
const TOGGLE_PRICING_PLAN_API = async (args) => {
  return apolloClient.query({
    query: TOGGLE_PRICING_PLAN_QUERY,
    variables: {
      togglePricingPlanId: args?.id,
    },
  });
};
function* togglePricingPlanGenerator({ payload }) {
  try {
    const {
      data: { togglePricingPlan },
    } = yield call(TOGGLE_PRICING_PLAN_API, payload?.args);
    yield put(togglePricingPlanSuccess(togglePricingPlan));
  } catch (e) {
    handleErrorMessage(e, 'Toggle Pricing Plan Error', null);
  }
}

export function* watchSubscribePricingPlan() {
  yield takeLatest(SUBSCRIBE_PRICING_PLAN, subscribePricingPlanGenerator);
}
const SUBSCRIBE_PRICING_PLAN_API = async (args) => {
  return apolloClient.query({
    query: SUBSCRIBE_PRICING_PLAN_QUERY,
    variables: {
      name: args?.name,
      email: args?.email,
      secondaryEmail: args?.secondary_email,
      token: args?.token,
      pricingPlanId: args?.pricing_plan_id,
    },
  });
};
function* subscribePricingPlanGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const {
      data: { subscribePricingPlan },
    } = yield call(SUBSCRIBE_PRICING_PLAN_API, args);
    updateCurrentUser(subscribePricingPlan.user);
    yield put(subscribePricingPlanSuccess());
    handleSuccessMessage(
      'Your have successfully subscribed to SafeWire!',
      'Congratulations!'
    );
    history.push(`${userRoot}/subscription/subscription-details`);
  } catch (e) {
    yield put(subscribePricingPlanError());
    handleErrorMessage(e, 'Subscription Error', null);
  }
}

export function* watchGetSubscription() {
  yield takeLatest(GET_SUBSCRIPTION, getSubscriptionGenerator);
}
const GET_SUBSCRIPTION_API = async () => {
  return apolloClient.query({
    query: GET_SUBSCRIPTION_QUERY,
    variables: {},
  });
};
function* getSubscriptionGenerator({ payload }) {
  const { history } = payload;
  try {
    const {
      data: { getSubscription },
    } = yield call(GET_SUBSCRIPTION_API);
    yield put(getSubscriptionSuccess(getSubscription));
    if (!getSubscription && history)
      history.push(`${userRoot}/subscription/select-pricing-plan`);
  } catch (e) {
    handleErrorMessage(e, 'Get Subscription Error', null);
  }
}

export function* watchCancelSubscription() {
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscriptionGenerator);
}
const CANCEL_SUBSCRIPTION_API = async () => {
  return apolloClient.mutate({
    mutation: CANCEL_SUBSCRIPTION_QUERY,
    variables: {},
  });
};
function* cancelSubscriptionGenerator({ payload }) {
  const { history } = payload;
  try {
    const {
      data: { cancelSubscription },
    } = yield call(CANCEL_SUBSCRIPTION_API);
    yield put(cancelSubscriptionSuccess());
    handleSuccessMessage(cancelSubscription.message, 'Subscription Canceled!');
    window.location.href = '/app/subscription/subscription-details';
  } catch (e) {
    handleErrorMessage(e, 'Cancel Subscription Error', null);
  }
}

export function* watchUserTransactionHistories() {
  yield takeLatest(
    GET_USER_TRANSACTION_HISTORY,
    getUserTransactionHistoriesGenerator
  );
}
const GET_USER_TRANSACTION_HISTORIES_API = async (args) => {
  return apolloClient.query({
    query: GET_USER_TRANSACTION_HISTORY_QUERY,
    variables: { stripeSubscriptionId: args?.stripe_subscription_id },
  });
};
function* getUserTransactionHistoriesGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { getCustomerTransationHistory },
    } = yield call(GET_USER_TRANSACTION_HISTORIES_API, args);
    yield put(getTransactionHistoriesSuccess(getCustomerTransationHistory));
  } catch (e) {
    handleErrorMessage(e, 'Error fetching Transaction histories', null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAllPricingPlans),
    fork(watchAddPricingPlan),
    fork(watchEditPricingPlan),
    fork(watchTogglePricingPlan),
    fork(watchGetPricingPlan),
    fork(watchSubscribePricingPlan),
    fork(watchGetSubscription),
    fork(watchCancelSubscription),
    fork(watchGetAllSubscriptions),
    fork(watchUserTransactionHistories),
  ]);
}
