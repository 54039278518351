import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  READ_ENTRY,
  READ_ENTRY_SUCCESS,
  READ_ENTRY_ERROR,
  VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_SUCCESS,
  VERIFY_RECAPTCHA_ERROR,
  CREATE_READER_LOG,
  CREATE_READER_LOG_SUCCESS,
  SEND_RECOVERY_CODE,
  SEND_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE,
  VERIFY_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE_ERROR,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  GET_ENTRY_FILE,
  GET_ENTRY_FILE_SUCCESS,
  GET_ENTRY_FILE_ERROR,
  CLEAR_ENTRY_FILE,
  CREATE_ISSUE_REPORT,
  CREATE_ISSUE_REPORT_SUCCESS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
} from 'redux/contants';

export const verifyReCaptcha = (args) => ({
  type: VERIFY_RECAPTCHA,
  payload: { args },
});
export const verifyReCaptchaSuccess = () => ({
  type: VERIFY_RECAPTCHA_SUCCESS,
  payload: {},
});
export const verifyReCaptchaError = () => ({
  type: VERIFY_RECAPTCHA_ERROR,
  payload: {},
});
export const verifyUser = (args) => ({
  type: VERIFY_USER,
  payload: { args },
});
export const verifyUserSuccess = () => ({
  type: VERIFY_USER_SUCCESS,
  payload: {},
});
export const verifyUserError = () => ({
  type: VERIFY_USER_ERROR,
  payload: {},
});
export const readEntry = (args) => ({
  type: READ_ENTRY,
  payload: { args },
});
export const readEntrySuccess = (entry) => ({
  type: READ_ENTRY_SUCCESS,
  payload: { entry },
});
export const readEntryError = (error) => ({
  type: READ_ENTRY_ERROR,
  payload: { error },
});

export const createReaderLog = (args) => ({
  type: CREATE_READER_LOG,
  payload: { args },
});
export const createReaderLogSuccess = () => ({
  type: CREATE_READER_LOG_SUCCESS,
  payload: {},
});

export const createIssueReport = (args) => ({
  type: CREATE_ISSUE_REPORT,
  payload: { args },
});
export const createIssueReportSuccess = () => ({
  type: CREATE_ISSUE_REPORT_SUCCESS,
  payload: {},
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const getEntryFile = (args) => ({
  type: GET_ENTRY_FILE,
  payload: args,
});
export const getEntryFileSuccess = (file) => ({
  type: GET_ENTRY_FILE_SUCCESS,
  payload: file,
});
export const getEntryFileError = () => ({
  type: GET_ENTRY_FILE_ERROR,
  payload: {},
});
export const clearEntryFile = () => ({
  type: CLEAR_ENTRY_FILE,
  payload: {},
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const sendRecoveryCode = (args) => ({
  type: SEND_RECOVERY_CODE,
  payload: { args },
});

export const sendRecoveryCodeSuccess = () => ({
  type: SEND_RECOVERY_CODE_SUCCESS,
  payload: {},
});

export const verifyRecoveryCode = (args) => ({
  type: VERIFY_RECOVERY_CODE,
  payload: { args },
});

export const verifyRecoveryCodeSuccess = () => ({
  type: VERIFY_RECOVERY_CODE_SUCCESS,
  payload: {},
});

export const verifyRecoveryCodeError = () => ({
  type: VERIFY_RECOVERY_CODE_ERROR,
  payload: {},
});

export const resetPassword = (args) => ({
  type: RESET_PASSWORD,
  payload: { args },
});
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: {},
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = () => ({
  type: REGISTER_USER_SUCCESS,
  payload: {},
});
export const registerUserError = () => ({
  type: REGISTER_USER_ERROR,
  payload: {},
});

export const getOrganization = (args) => ({
  type: GET_ORGANIZATION,
  payload: { args },
});
export const getOrganizationSuccess = (organization) => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload: organization,
});
export const getOrganizationError = () => ({
  type: GET_ORGANIZATION_ERROR,
  payload: {},
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
