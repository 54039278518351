const { gql } = require('@apollo/client');

const UPDATE_PERSONAL_INFORMATION_QUERY = gql(`
    mutation($name: String!, $secondaryEmail: String!){
        updatePersonalInformation(name: $name, secondary_email: $secondaryEmail) {
            id, secondary_email, name, email, is_email_verified, deleted_at, admin_guid, stripe_customer_id
        }
    }`);

const GET_NOTIFICATIONS_QUERY = gql(`
    query GetNotifications {
        getNotifications {
            id, content, icon, created_at, seen, type, created_at, receiver { id, name }, sender { id, name }
        }
    }`);

const RESEND_VERIFICATION_EMAIL_QUERY = gql(`
    query($email: String!){
        resendVerificationEmail(email: $email) {
            status, message 
        }
    }`);

const MARK_NOTIFICATIONS_AS_SEEN_QUERY = gql(`
    mutation{
        markNotificationsAsSeen {
            id, content, icon, created_at, seen, type, created_at, receiver { id, name }, sender { id, name }
        }
    }`);

const UPDATE_PASSWORD_QUERY = gql(`
    mutation($newPassword: String!, $currentPassword: String!){
        updatePassword(new_password: $newPassword, current_password: $currentPassword) {
            status, message
        }
    }`);

const DELETE_USER_QUERY = gql(`
    mutation($password: String!){
        deleteUser(password: $password) {
            status, message
        }
    }`);

const ALL_ENTRIES_QUERY = gql(`
    query($limit: Int!, $page: Int!, $search: String!, $orderByField: OrderByFieldEntries!, $orderDirection: OrderDirection!, $userId: Int, $status: StatusEntries!){
        allEntries(limit: $limit, page: $page, search: $search, orderByField: $orderByField, orderDirection: $orderDirection, userId: $userId, status: $status) {
            rows {
                total_reader_logs, total_issue_reports, nickname, id, salt, url, expires_at, created_at, deleted_at, template,
                user {
                    id, name
                }
            }
            count, page, allPages, 
        }
    }`);

const POSTPONE_ENTRY_EXPIRATION_QUERY = gql(`
    mutation($postponeEntryExpirationId: ID!, $expiresAt: String!, $isFuture: Boolean!){
        postponeEntryExpiration(id: $postponeEntryExpirationId, expires_at: $expiresAt, is_future: $isFuture) {
            status, message
        }
    }`);

const ENTRIES_SUMMARY_QUERY = gql(`
    query{
        entriesSummary {
            activeEntries, deletedEntries, expiredEntries
        }
    }`);

const DELETE_ENTRY_QUERY = gql(`
    mutation($deleteEntryId: ID!){
        deleteEntry(id: $deleteEntryId) {
            status, message
        }
    }`);

const RESTORE_ENTRY_QUERY = gql(`
    mutation($restoreEntryId: ID!){
        restoreEntry(id: $restoreEntryId) {
            status, message
        }
    }`);

// TODO: Add 'metadata' and 'admin_metadata' here when available
const USER_SUMMARY_QUERY = gql(`
    query{
        userSummary{
          is_email_verified,
        }
    }`);

module.exports = {
  DELETE_ENTRY_QUERY,
  ENTRIES_SUMMARY_QUERY,
  ALL_ENTRIES_QUERY,
  UPDATE_PERSONAL_INFORMATION_QUERY,
  UPDATE_PASSWORD_QUERY,
  DELETE_USER_QUERY,
  POSTPONE_ENTRY_EXPIRATION_QUERY,
  USER_SUMMARY_QUERY,
  GET_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_SEEN_QUERY,
  RESEND_VERIFICATION_EMAIL_QUERY,
  RESTORE_ENTRY_QUERY,
};
