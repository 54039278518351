/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { userRoot } from 'constants/defaultValues';
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { handleErrorMessage, handleSuccessMessage } from 'helpers/Utils';
import { encryptOnClient } from 'services/crypto';
import { BULK_DELETE_ENTRIES, BULK_POSTPONE_EXPIRATION_ENTRIES, BULK_RESTORE_ENTRIES, CREATE_ENTRY, ENTRY_ISSUE_REPORTS, ENTRY_READER_LOGS, GET_ENTRY_FILE_URL } from 'redux/contants';
import { createEntrySuccess, getEntryIssueReportsSuccess, getEntryFileUrlSuccess, getEntryReaderLogsSuccess, bulkDeleteEntriesSuccess, bulkDeleteEntriesError, bulkRestoreEntriesSuccess, bulkRestoreEntriesError, bulkPostponeExpirationEntriesSuccess, bulkPostponeExpirationEntriesError, createEntryError } from 'redux/actions';
import { BULK_DELETE_ENTRIES_QUERY, BULK_POSTPONE_EXPIRATION_ENTRIES_QUERY, BULK_RESTORE_ENTRIES_QUERY, CREATE_ENTRY_QUERY, ENTRY_ISSUE_REPORTS_QUERY, ENTRY_READER_LOGS_QUERY, GET_ENTRY_FILE_URL_QUERY } from './queries';

export function* watchCreateEntry() {
  yield takeEvery(CREATE_ENTRY, createEntryGenerator);
}
export const CREATE_ENTRY_API = async (entry) => {
  return apolloClient.mutate({
    mutation: CREATE_ENTRY_QUERY,
    variables: {
      nickname: entry.nickname, // Can't encrypt yet without messing up search/paging (TODO#142)
      salt: entry.salt, // Random, no encryption needed
      url: entry.url, // Random, no encryption needed
      jsonPayload: entry.jsonPayload, // Already encrypted through 0K
      template: entry.template, // Acceptable that the template name is in clear
      expiresAt: entry.expiresAt,
      encryptionVersion: entry.encryptionVersion,
      hashVersion: entry.hashVersion, // TODO: Delete this
      compoundKeyVersion: entry.compoundKeyVersion, // TODO: Delete this
      metadata: encryptOnClient(entry.metadata),
      entryFiles: entry.entryFiles
    }
  });
}
export function* createEntryGenerator({ payload }) {
  const { entry, history } = payload;
  try {
    const { data: { createEntry }} = yield call(CREATE_ENTRY_API, entry);
    yield put(createEntrySuccess({
      nickname: createEntry.nickname,
      url: createEntry.url,
      salt: createEntry.salt,
      json_payload: createEntry.json_payload,
      expires_at: createEntry.expires_at
    }));
  } catch (e) {
    handleErrorMessage(e, 'Create Entry Error', history);
    yield put(createEntryError());
  }
}

export function* watchGetEntryFileUrl() {
  yield takeEvery(GET_ENTRY_FILE_URL, getEntryFileUrlGenerator);
}
const GET_ENTRY_FILE_URL_API = async () => {
  return apolloClient.query({
    query: GET_ENTRY_FILE_URL_QUERY,
    variables: {}
  });
}
function* getEntryFileUrlGenerator() {
  try {
    const { data: { getEntryFileUrl }} = yield call(GET_ENTRY_FILE_URL_API);
    yield put(getEntryFileUrlSuccess(getEntryFileUrl));
  } catch (e) {
    handleErrorMessage(e, 'Get Entry File Url Error', false);
  }
}

export function* watchEntryIssueReports() {
  yield takeEvery(ENTRY_ISSUE_REPORTS, entryIssueReportsGenerator);
}
const ENTRY_ISSUE_REPORTS_API = async (args) => {
  if(args.entry_id){
    return apolloClient.query({
      query: ENTRY_ISSUE_REPORTS_QUERY,
      variables: {
        entryId: args.entry_id
      }
    });
  }
  return apolloClient.query({
    query: ENTRY_ISSUE_REPORTS_QUERY,
    variables: {
      organizationId: args.organization_id
    }
  });
}
function* entryIssueReportsGenerator({ payload }) {
  try {
    const { data: { entryIssueReports }} = yield call(ENTRY_ISSUE_REPORTS_API, payload.args);
    yield put(getEntryIssueReportsSuccess(entryIssueReports));
  } catch (e) {
    handleErrorMessage(e, 'Entry Issue Reports', false);
  }
}

export function* watchEntryReaderLogs() {
  yield takeEvery(ENTRY_READER_LOGS, entryReaderLogsGenerator);
}
const ENTRY_READER_LOGS_API = async (args) => {
  return apolloClient.query({
    query: ENTRY_READER_LOGS_QUERY,
    variables: {
      entryId: args.id
    }
  });
}
function* entryReaderLogsGenerator({ payload }) {
  try {
    const { data: { entryReaderLogs }} = yield call(ENTRY_READER_LOGS_API, payload.args);
    yield put(getEntryReaderLogsSuccess(entryReaderLogs));
  } catch (e) {
    handleErrorMessage(e, 'Entry Reader Logs', false);
  }
}

export function* watchBulkDeleteEntries() {
  yield takeEvery(BULK_DELETE_ENTRIES, bulkDeleteEntriesGenerator);
}
const BULK_DELETE_ENTRIES_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_DELETE_ENTRIES_QUERY,
    variables: {
      ids: args.ids
    }
  });
}
function* bulkDeleteEntriesGenerator({ payload }) {
  try {
    const { data: { bulkDeleteEntries }} = yield call(BULK_DELETE_ENTRIES_API, payload.args);
    yield put(bulkDeleteEntriesSuccess());
    handleSuccessMessage(bulkDeleteEntries, 'Entries Deleted');
  } catch (e) {
    yield put(bulkDeleteEntriesError());
    handleErrorMessage(e, 'Entries Deleted', false);
  }
}

export function* watchBulkRestoreEntries() {
  yield takeEvery(BULK_RESTORE_ENTRIES, bulkRestoreEntriesGenerator);
}
const BULK_RESTORE_ENTRIES_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_RESTORE_ENTRIES_QUERY,
    variables: {
      ids: args.ids
    }
  });
}
function* bulkRestoreEntriesGenerator({ payload }) {
  try {
    const { data: { bulkRestoreEntries }} = yield call(BULK_RESTORE_ENTRIES_API, payload.args);
    yield put(bulkRestoreEntriesSuccess());
    handleSuccessMessage(bulkRestoreEntries, 'Entries Restored');
  } catch (e) {
    yield put(bulkRestoreEntriesError());
    handleErrorMessage(e, 'Entries Restored', false);
  }
}

export function* watchBulPostponeExpirationEntries() {
  yield takeEvery(BULK_POSTPONE_EXPIRATION_ENTRIES, bulkPostponeExpirationEntriesGenerator);
}
const BULK_POSTPONE_EXPIRATION_ENTRIES_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_POSTPONE_EXPIRATION_ENTRIES_QUERY,
    variables: {
      ids: args.ids,
      expiresAt: args.expires_at
    }
  });
}
function* bulkPostponeExpirationEntriesGenerator({ payload }) {
  try {
    const { data: { bulkPostponeEntryExpiration }} = yield call(BULK_POSTPONE_EXPIRATION_ENTRIES_API, payload.args);
    yield put(bulkPostponeExpirationEntriesSuccess());
    handleSuccessMessage(bulkPostponeEntryExpiration, 'Entries Expiration Date Updated');
  } catch (e) {
    yield put(bulkPostponeExpirationEntriesError());
    handleErrorMessage(e, 'Entries Expiration Date Update', false);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateEntry),
    fork(watchGetEntryFileUrl),
    fork(watchEntryIssueReports),
    fork(watchEntryReaderLogs),
    fork(watchBulkDeleteEntries),
    fork(watchBulkRestoreEntries),
    fork(watchBulPostponeExpirationEntries)
  ]);
}
