/* eslint-disable import/prefer-default-export */
import {
  ADD_PRICING_PLAN,
  ADD_PRICING_PLAN_ERROR,
  ADD_PRICING_PLAN_SUCCESS,
  ALL_PRICING_PLANS,
  ALL_PRICING_PLANS_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_PRICING_PLAN,
  CLEAR_TRANSACTION_HISTORY,
  EDIT_PRICING_PLAN,
  EDIT_PRICING_PLAN_SUCCESS,
  GET_ALL_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_PRICING_PLAN,
  GET_PRICING_PLAN_ERROR,
  GET_PRICING_PLAN_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_USER_TRANSACTION_HISTORY,
  GET_USER_TRANSACTION_HISTORY_SUCCESS,
  SUBSCRIBE_PRICING_PLAN,
  SUBSCRIBE_PRICING_PLAN_ERROR,
  SUBSCRIBE_PRICING_PLAN_SUCCESS,
  TOGGLE_PRICING_PLAN,
  TOGGLE_PRICING_PLAN_SUCCESS,
} from 'redux/contants';

export const allPricingPlans = () => ({
  type: ALL_PRICING_PLANS,
  payload: {},
});
export const allPricingPlansSuccess = (data) => ({
  type: ALL_PRICING_PLANS_SUCCESS,
  payload: data,
});
export const getAllSubscriptions = (args) => ({
  type: GET_ALL_SUBSCRIPTIONS,
  payload: { args },
});
export const getAllSubscriptionsSuccess = (data) => ({
  type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});
export const getPricingPlan = (args) => ({
  type: GET_PRICING_PLAN,
  payload: { args },
});
export const getPricingPlanSuccess = (data) => ({
  type: GET_PRICING_PLAN_SUCCESS,
  payload: data,
});
export const getPricingPlanError = () => ({
  type: GET_PRICING_PLAN_ERROR,
  payload: {},
});
export const addPricingPlan = (args, history) => ({
  type: ADD_PRICING_PLAN,
  payload: { args, history },
});
export const addPricingPlanSuccess = (data) => ({
  type: ADD_PRICING_PLAN_SUCCESS,
  payload: data,
});
export const addPricingPlanError = () => ({
  type: ADD_PRICING_PLAN_ERROR,
  payload: {},
});
export const togglePricingPlan = (args) => ({
  type: TOGGLE_PRICING_PLAN,
  payload: { args },
});
export const togglePricingPlanSuccess = (data) => ({
  type: TOGGLE_PRICING_PLAN_SUCCESS,
  payload: data,
});
export const editPricingPlan = (args, history) => ({
  type: EDIT_PRICING_PLAN,
  payload: { args, history },
});
export const editPricingPlanSuccess = (data) => ({
  type: EDIT_PRICING_PLAN_SUCCESS,
  payload: data,
});
export const subscribePricingPlan = (args, history) => ({
  type: SUBSCRIBE_PRICING_PLAN,
  payload: { args, history },
});
export const subscribePricingPlanSuccess = (data) => ({
  type: SUBSCRIBE_PRICING_PLAN_SUCCESS,
  payload: data,
});
export const subscribePricingPlanError = () => ({
  type: SUBSCRIBE_PRICING_PLAN_ERROR,
  payload: {},
});
export const getSubscription = (history) => ({
  type: GET_SUBSCRIPTION,
  payload: { history },
});
export const getSubscriptionSuccess = (data) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: data,
});
export const cancelSubscription = (history) => ({
  type: CANCEL_SUBSCRIPTION,
  payload: { history },
});
export const cancelSubscriptionSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload: {},
});
export const getTransactionHistories = (args) => ({
  type: GET_USER_TRANSACTION_HISTORY,
  payload: { args },
});
export const getTransactionHistoriesSuccess = (transactionHistory) => ({
  type: GET_USER_TRANSACTION_HISTORY_SUCCESS,
  payload: transactionHistory,
});
export const clearPricingPlan = () => ({
  type: CLEAR_PRICING_PLAN,
  payload: {},
});
export const clearTransactionHistories = () => ({
  type: CLEAR_TRANSACTION_HISTORY,
  payload: {},
});
