/* eslint-disable prettier/prettier */
import CryptoJS from 'crypto-js';
import Base32 from 'hi-base32-custom';

// Good references for password protection:
// https://crackstation.net/hashing-security.htm

// From this website, good slow hash functions:
// - PBKDF2
// - bcrypt

// DO use:
// Well - designed key stretching algorithms such as PBKDF2, bcrypt, and scrypt.
//  OpenWall's Portable PHP password hashing framework
// My implementations of PBKDF2 in PHP, C#, Java, and Ruby.
// Secure versions of crypt($2y$, $5$, $6$)

// DO NOT use:
// Fast cryptographic hash functions such as MD5, SHA1, SHA256, SHA512, RipeMD, WHIRLPOOL, SHA3, etc.
// Insecure versions of crypt($1$, $2$, $2x$, $3$).
// Any algorithm that you designed yourself.Only use technology that is in the public domain and has been well - tested by experienced cryptographers.

// Even though there are no cryptographic attacks on MD5 or SHA1 that make their hashes easier to crack,
// they are old and are widely considered (somewhat incorrectly) to be inadequate for password storage.So I don't recommend using them.
// An exception to this rule is PBKDF2, which is frequently implemented using SHA1 as the underlying hash function.

// Good website for testing hashing and encryption:
// https://gchq.github.io/CyberChef/

// Javascript encryption
// https://dev.to/halan/4-ways-of-symmetric-cryptography-and-javascript-how-to-aes-with-javascript-3o1b
// http://crypto.stanford.edu/sjcl/
// https://www.npmjs.com/package/crypto-js

// On purpose, we store the key in the client code, and not from the backend. If the backend is
// attacked, the attacker will have to also attack the client code to be able to figure out the
// actual key. Storing both fixed keys and salts in the backend is making it too easy for an
// attacker. And in any case, an attacker on the client could also figure out user.encryption_key
// and user.encryption_iv. We also want to decrypt while there is no user.
// TODO: Improve this further. Leverage versioning in the encrypted payload itself.
// TODO: Encrypt on both client and backend, but with different keys.
// TODO: Verify in the backend that key fields are encrypted by the client

// Consider using https://jsfuck.com to obfuscate the key.
const a0 = 'jE3LDI0NywxODUsMsOTIsMjIyLDE1NywNywyMTAsMjM0LDE4';
const a0Prefix = '|A0|'; // Backend will use a different prefix so it will be clear if an encryption is incorrect

// TODO: Use |B0| for backend --> What should be stored in the DB
// TODO: Use |C0| for client --> What should be sent and received by the client
// Eventually we won't have |A0| fields anymore, or we could upgrade them over time.

export function encryptOnClient(text) {
  if (text.startsWith(a0Prefix)) {
    // If somehow, this was already encrypted, do not encrypt it again
    // TODO: Report this case as this would be a bug
    return text;
  }
  // We do not pass an IV, which will generate a new salt every time (as shown in the unit-tests).
  // We prefix with '|A0|' to indicate the encryption implementation and key.
  return `${a0Prefix}${CryptoJS.AES.encrypt(text, a0).toString()}`;
}

export function decryptOnClient(text) {
  if (text?.startsWith(a0Prefix)) {
    // 4 for length of '|A0|'
    return CryptoJS.AES.decrypt(text.substring(4), a0).toString(CryptoJS.enc.Utf8);
  }
  return text;
}

// TBD if we want to hash the email/password only on the client, or also on the backend
// Checking the pair email/password on the server will improve the security (both have to be true to validate the user),
// so a hacker cannot check if an email exists (first step before trying to hack the password, or try a leaked password).

// We expect multiple versions for the encryption algorithms over time. Usually more secure, sometimes faster.
// Also a variation of algorithms make the life harder on hackers, as they cannot use standard dictionaries.

// Algorithm versions:
//  Each algorithm will be versionned, and a sinle integer will aggregate all versions through a hex mask. (0xAABBCCDD)
//
//  CompoundKey Version:
//    Example:
//      (['foo', 'bar'], 'salt') ==> 'salt_foo_bar'
//    We expect few iterations often on this one. Most other implementations can leverage this V0 directly.
//    We don't expect code to directly interact with this.
//    Version mask 0xFF000000
//
//  Hash Version:
//    Example:
//      (['foo', 'bar'], 'salt') ==> SHA256('salt_foo_bar')
//    We expect many iterations on this one.
//    Version mask: 0x00FF0000
//
//  Encryption Version:
//    Example:
//      {JSON: 'blob'} ==> AES({JSON: 'blob'})
//    We expect many iterations in this one.
//    Version mask: 0x0000FF00
//
//  Verification Code Version:
//    Example:
//      SHA256('salt_foo_bar') ==> TBD('salt_foo_bar')
//    We expect only few iterations of this one.
//    Version mask: 0x000000FF
//

function generateSaltedCompoundKeyV0(compoundKeys: string[], salt: string): String {
  return salt.concat('_', compoundKeys.join('_'));
}

export function generateSaltedCompoundKey(compoundKeys: string[], salt: string, compoundKeyVersion: number): string {
  switch (compoundKeyVersion) {
    case 0:
    default:
      return generateSaltedCompoundKeyV0(compoundKeys, salt);
  }
}

export function getSupportedCompoundKeyVersions() {
  return {
    supported: [0],
    recommended: 0,
    deprecated: [],
  };
}

function generateHashedEncryptionKeyV0(saltedCompoundKey: string): string {
  // https://gchq.github.io/CyberChef/#recipe=SHA2('256',64,160)&input=c2FsdF9mb29fYmFy
  // Equivalent of 64 rounds of SHA2 256 bits
  return CryptoJS.SHA256(saltedCompoundKey).toString();
}

export function generateHashedEncryptionKey(saltedCompoundKey: string, hashVersion: number): string {
  switch (hashVersion) {
    case 0:
    default:
      return generateHashedEncryptionKeyV0(saltedCompoundKey);
  }
}

export function getSupportedHashVersions() {
  return {
    supported: [0],
    recommended: 0,
    deprecated: [],
  };
}

function splice(str, index, count) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  let sliceIndex = index;
  if (sliceIndex < 0) {
    sliceIndex = str.length + sliceIndex;
    if (sliceIndex < 0) {
      sliceIndex = 0;
    }
  }
  return str.slice(0, sliceIndex) + str.slice(sliceIndex + count);
}

function generateVerificationCodeV0(encryptionKey: string): string {
  // We want to make the logic of the verification code as simple as possible, while not leaking any key information.
  // The original salted encryption key would be a bad choice as it is too close to the source key.
  // The encryption key is a better choice, as it went through a bunch of hashing already.
  // However, it is the encryption key, so if one could reverse engineer it, they could decrypt the payload.

  // So let's shuffle things a bit, so it would be too costly to reverse engineer/brute force it.
  // 1. Remove 32 characters at position 7
  // 2. Replace characters 'a' => '2' and '5' => 'f'
  // 3. 64 rounds of SHA2 256 bits hashing

  let obfuscatedVerificationCode = splice(encryptionKey, 7, 32);
  obfuscatedVerificationCode = obfuscatedVerificationCode.replace(/a/g, '2').replace(/5/g, 'f');
  const fullEncryptionCode = CryptoJS.SHA256(obfuscatedVerificationCode).toString();

  // Limit it to 40 bits, so 10 hex characters. A future version will XOR each 40 bits range, but
  // this is good enough for the moment.
  const strippedEncryptionCode = fullEncryptionCode.slice(0, 10);

  // This loop is sloppy, improve this.
  const bytes = [];
  for (let i = 0; i < strippedEncryptionCode.length; i += 2) {
    bytes.push(parseInt(strippedEncryptionCode.slice(i, i + 2), 16));
  }

  const verificationCode = Base32.encode(bytes);
  if (verificationCode.length === 8) {
    // This is the expected size, but we are being paranoid here
    // Add the dash in the middle of the verification code.
    return verificationCode.slice(0, 4).concat('-', verificationCode.slice(4));
  }
  return verificationCode;
}

export function generateVerificationCode(encryptionKey: string, verificationCodeVersion: number): string {
  switch (verificationCodeVersion) {
    case 0:
    default:
      return generateVerificationCodeV0(encryptionKey);
  }
}

export function getSupportedVerificationCodeVersions() {
  return {
    supported: [0],
    recommended: 0,
    deprecated: [],
  };
}

const encryptV0Salt = '0123456789abcdef';
const encryptV0IV = '1122334455667788';

function encryptPayloadV0(payload: string, encryptionKey: string): string {
  const salt = CryptoJS.enc.Utf8.parse(encryptV0Salt);
  const iv = CryptoJS.enc.Utf8.parse(encryptV0IV);
  const key256Bits = CryptoJS.PBKDF2(encryptionKey, salt, { keySize: 256 / 32, iterations: 1 });
  return CryptoJS.AES.encrypt(payload, key256Bits, { iv }).toString();
}

function encryptPayloadV1(payload: string, encryptionKey: string): string {
  return CryptoJS.AES.encrypt(payload, encryptionKey).toString();
}

function decryptPayloadV0(encryptedPayload: string, encryptionKey: string): string {
  const salt = CryptoJS.enc.Utf8.parse(encryptV0Salt);
  const iv = CryptoJS.enc.Utf8.parse(encryptV0IV);
  const key256Bits = CryptoJS.PBKDF2(encryptionKey, salt, { keySize: 256 / 32 });
  return CryptoJS.AES.decrypt(encryptedPayload, key256Bits, { iv }).toString(CryptoJS.enc.Utf8);
}

function decryptPayloadV1(encryptedPayload: string, encryptionKey: string): string {
  return CryptoJS.AES.decrypt(encryptedPayload, encryptionKey).toString(CryptoJS.enc.Utf8);
}

export function encryptPayload(payload: string, encryptionKey: string, encryptionVersion: number): string {
  switch (encryptionVersion) {
    case 0:
      return encryptPayloadV0(payload, encryptionKey);
    default:
    case 1:
      return encryptPayloadV1(payload, encryptionKey);
  }
}

export function decryptPayload(encryptedPayload: string, encryptionKey: string, encryptionVersion: number): string {
  try {
    switch (encryptionVersion) {
      case 0:
        return decryptPayloadV0(encryptedPayload, encryptionKey);
      default:
      case 1:
        return decryptPayloadV1(encryptedPayload, encryptionKey);
    }
  } catch {
    // If the encryption key is incorrect, it may throw an exception (during the UTF8 conversion).
    return null;
  }
}

export function getSupportedEncryptionVersions() {
  return {
    supported: [0, 1],
    recommended: 1,
    deprecated: [0], // Keep this one for unit-tests (as there is a constant key/iv)
  };
}

export function getRecommendedCryptoAlgorithmVersions() {
  return {
    compoundKey: getSupportedCompoundKeyVersions().recommended,
    hash: getSupportedHashVersions().recommended,
    encryption: getSupportedEncryptionVersions().recommended,
    verificationCode: getSupportedVerificationCodeVersions().recommended,
  };
}

/* eslint-disable no-bitwise */
export function getCryptoAlgorithmVersion(compoundKeyVersion: number, hashVersion: number, encryptionVersion: number, verificationCodeVersion: number) {
  return ((compoundKeyVersion & 0xff) << 24) | ((hashVersion & 0xff) << 16) | ((encryptionVersion & 0xff) << 8) | (verificationCodeVersion & 0xff);
}

export function getCompoundKeyVersion(cryptoAlgorithmVersion: number) {
  return ((cryptoAlgorithmVersion & 0xff000000) >> 24);
}

export function getHashVersion(cryptoAlgorithmVersion: number) {
  return ((cryptoAlgorithmVersion & 0x00ff0000) >> 16);
}

export function getEncryptionVersion(cryptoAlgorithmVersion: number) {
  return ((cryptoAlgorithmVersion & 0x0000ff00) >> 8);
}

export function getVerificationCodeVersion(cryptoAlgorithmVersion: number) {
  return (cryptoAlgorithmVersion & 0x000000ff);
}
/* eslint-enable no-bitwise */

// TODO: Move these to the backend. We should not let the client generate a SALT or a URL.

export function generateSalt() {
  // Generate 6 characters of 8 bits, which will generate 8 characters of 6 bits
  const random = CryptoJS.lib.WordArray.random(6);
  return CryptoJS.enc.Base64.stringify(random);
}

export function generateUrl() {
  // Generate 5 characters of 8 bits, which will generate 8 characters of 5 bits
  const random = CryptoJS.lib.WordArray.random(5).toString();
  const bytes = [];
  for (let i = 0; i < random.length; i += 2) {
    bytes.push(parseInt(random.slice(i, i + 2), 16));
  }

  // This will be exactly 8 characters long in Base32 - 40 Bits dimension
  return Base32.encode(bytes);
}
