const { gql } = require('@apollo/client');

// TODO: Verify if everything here is in the proper file (auth section?)

const LOGIN_USER_QUERY = gql(`
  mutation LoginUser($email: String!, $password: String!){
      loginUser(email: $email, password: $password){
          user{
              name, email, id, is_email_verified, deleted_at, secondary_email, admin_guid, stripe_customer_id,
              subscriptions {
                id, start_at, end_at
              },
              organization {
                name, unique_id
              }
          },
          token, encryption_key, encryption_iv
      }
  }`);

const REGISTER_USER_QUERY = gql(`
  mutation($name: String!, $email: String!, $password: String!, $adminGuid: String){
    registerUser(name: $name, email: $email, password: $password, admin_guid: $adminGuid) {
      id, name, email, is_email_verified, admin_guid, stripe_customer_id
    }
  }
`);

const RESET_PASSWORD_QUERY = gql(`
  mutation($code: String!, $password: String!){
    resetPassword(code: $code, password: $password) {
      message, status
    }
  }
`);

const SEND_RECOVERY_CODE_QUERY = gql(`
    mutation($email: String!){
        sendRecoveryCode(email: $email) {
            status, message
        }
    }
`);

const READ_ENTRY_QUERY = gql(`
  query($url: String!){
    readEntry(url: $url) {
      url, salt, json_payload, template, id,
      entry_files {
        id, filename, size, type, encrypted_filename
      },
      encryption_version, metadata, 
      user { 
        is_email_verified, 
        organization {
          name, unique_id
        }
        tasks {
          status, task_slug
        }
      }
    }
  }`);

const CREATE_READER_LOG_QUERY = gql(`
  mutation($createReaderLogId: ID!, $result: Boolean!, $location: String!, $ip: String!){
    createReaderLog(id: $createReaderLogId, result: $result, location: $location, ip: $ip) {
      message, status
    }
  }
`);

const CREATE_ISSUE_REPORT_QUERY = gql(`
  mutation($emailPayload: String!, $entryId: Int, $organizationId: Int, $message: String!, $type: String!, $name: String, $email: String, $url: String!, $ip: String!, $location: String!){
    createIssueReport(email_payload: $emailPayload, entry_id: $entryId, organization_id: $organizationId, message: $message, type: $type, name: $name, email: $email, url: $url, ip: $ip, location: $location) {
      status, message
    }
  }
`);

const VERIFY_RECAPTCHA_QUERY = gql(`
  mutation($responseKey: String!){
    verifyReCaptcha(response_key: $responseKey) {
      status,message
    }
  }`);

const VERIFY_RECOVERY_CODE_QUERY = gql(`
  mutation($code: String!){
    verifyRecoveryCode(code: $code) {
      status, message
    }
  }
`);

const VERIFY_USER_QUERY = gql(`
  mutation($code: String!){
    verifyUser(code: $code) {
      status, message
    }
  }
`);

const GET_ENTRY_FILE_QUERY = gql(`
  query($filename: String!){
    readEntryFile(filename: $filename) {
      status, body
    }
  }
`);

const GET_ORGANIZATION_QUERY = gql(`
  query($uniqueId: String!){
    getOrganization(unique_id: $uniqueId) {
      id, name, details, created_at, deleted_at, unique_id
    }
  }
`);

module.exports = {
  GET_ORGANIZATION_QUERY,
  VERIFY_USER_QUERY,
  LOGIN_USER_QUERY,
  READ_ENTRY_QUERY,
  VERIFY_RECAPTCHA_QUERY,
  CREATE_READER_LOG_QUERY,
  RESET_PASSWORD_QUERY,
  SEND_RECOVERY_CODE_QUERY,
  VERIFY_RECOVERY_CODE_QUERY,
  REGISTER_USER_QUERY,
  GET_ENTRY_FILE_QUERY,
  CREATE_ISSUE_REPORT_QUERY,
};
