import {
  ADD_PRICING_PLAN,
  ADD_PRICING_PLAN_SUCCESS,
  ALL_PRICING_PLANS,
  ALL_PRICING_PLANS_ERROR,
  ALL_PRICING_PLANS_SUCCESS,
  TOGGLE_PRICING_PLAN,
  TOGGLE_PRICING_PLAN_SUCCESS,
  EDIT_PRICING_PLAN,
  EDIT_PRICING_PLAN_SUCCESS,
  GET_PRICING_PLAN,
  GET_PRICING_PLAN_ERROR,
  GET_PRICING_PLAN_SUCCESS,
  SUBSCRIBE_PRICING_PLAN,
  SUBSCRIBE_PRICING_PLAN_ERROR,
  SUBSCRIBE_PRICING_PLAN_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_ALL_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_USER_TRANSACTION_HISTORY,
  GET_USER_TRANSACTION_HISTORY_SUCCESS,
  CLEAR_PRICING_PLAN,
  CLEAR_TRANSACTION_HISTORY,
  ADD_PRICING_PLAN_ERROR,
} from 'redux/contants';

const INIT_STATE = {
  loading: false,
  error: null,
  pricingPlans: [],
  pricingPlan: null,
  subscription: null,
  subscriptions: [],
  transactionHistories: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_PRICING_PLANS:
      return { ...state, loading: true, error: null };
    case ALL_PRICING_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlans: action.payload,
      };
    case GET_ALL_SUBSCRIPTIONS:
      return { ...state, loading: true, error: null };
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        subscriptions: action.payload,
      };
    case ALL_PRICING_PLANS_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
        pricingPlans: [],
      };
    case GET_PRICING_PLAN:
      return { ...state, loading: true, error: null };
    case GET_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlan: action.payload,
      };
    case GET_PRICING_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
        pricingPlan: null,
      };
    case ADD_PRICING_PLAN:
      return { ...state, loading: true, error: null };
    case ADD_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlans: action.payload,
      };
    case ADD_PRICING_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case EDIT_PRICING_PLAN:
      return { ...state, loading: true, error: null };
    case EDIT_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlans: action.payload,
      };
    case SUBSCRIBE_PRICING_PLAN:
      return { ...state, loading: true, error: null };
    case SUBSCRIBE_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUBSCRIBE_PRICING_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TOGGLE_PRICING_PLAN:
      return { ...state, loading: true, error: null };
    case TOGGLE_PRICING_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlans: action.payload,
      };
    case GET_SUBSCRIPTION:
      return { ...state, loading: true, error: null };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        subscription: action.payload,
      };
    case CANCEL_SUBSCRIPTION:
      return { ...state, loading: true, error: null };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        subscription: null,
      };
    case CLEAR_PRICING_PLAN:
      return {
        ...state,
        loading: false,
        error: null,
        pricingPlan: null,
      };
    case GET_USER_TRANSACTION_HISTORY:
      return { ...state, loading: true, error: null };
    case GET_USER_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactionHistories: action.payload,
      };
    case CLEAR_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: false,
        error: null,
        transactionHistories: null,
      };
    default:
      return { ...state };
  }
};
