/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';
export const VERIFY_RECAPTCHA_SUCCESS = 'VERIFY_RECAPTCHA_SUCCESS';
export const VERIFY_RECAPTCHA_ERROR = 'VERIFY_RECAPTCHA_ERROR';
export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_ERROR = 'VERIFY_USER_ERROR';

/* PROFILE */
export const UPDATE_PERSONAL_INFORMATION = 'UPDATE_PERSONAL_INFORMATION';
export const UPDATE_PERSONAL_INFORMATION_SUCCESS =
  'UPDATE_PERSONAL_INFORMATION_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

// ENTRIES
export const BULK_DELETE_ENTRIES = 'BULK_DELETE_ENTRIES';
export const BULK_DELETE_ENTRIES_SUCCESS = 'BULK_DELETE_ENTRIES_SUCCESS';
export const BULK_DELETE_ENTRIES_ERROR = 'BULK_DELETE_ENTRIES_ERROR';
export const BULK_RESTORE_ENTRIES = 'BULK_RESTORE_ENTRIES';
export const BULK_RESTORE_ENTRIES_SUCCESS = 'BULK_RESTORE_ENTRIES_SUCCESS';
export const BULK_RESTORE_ENTRIES_ERROR = 'BULK_RESTORE_ENTRIES_ERROR';
export const BULK_POSTPONE_EXPIRATION_ENTRIES =
  'BULK_POSTPONE_EXPIRATION_ENTRIES';
export const BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS =
  'BULK_POSTPONE_EXPIRATION_ENTRIES_SUCCESS';
export const BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR =
  'BULK_POSTPONE_EXPIRATION_ENTRIES_ERROR';
export const ENTRY_ISSUE_REPORTS = 'ENTRY_ISSUE_REPORTS';
export const ENTRY_ISSUE_REPORTS_SUCCESS = 'ENTRY_ISSUE_REPORTS_SUCCESS';
export const ENTRY_ISSUE_REPORTS_CLEAR = 'ENTRY_ISSUE_REPORTS_CLEAR';
export const ENTRY_READER_LOGS = 'ENTRY_READER_LOGS';
export const ENTRY_READER_LOGS_SUCCESS = 'ENTRY_READER_LOGS_SUCCESS';
export const ENTRY_READER_LOGS_CLEAR = 'ENTRY_READER_LOGS_CLEAR';
export const CLEAR_ENTRY = 'CLEAR_ENTRY';
export const CLEAR_ENTRY_FILE = 'CLEAR_ENTRY_FILE';
export const GET_ENTRY_FILE_URL = 'GET_ENTRY_FILE_URL';
export const GET_ENTRY_FILE_URL_SUCCESS = 'GET_ENTRY_FILE_URL_SUCCESS';
export const GET_ENTRY_FILE = 'GET_ENTRY_FILE';
export const GET_ENTRY_FILE_SUCCESS = 'GET_ENTRY_FILE_SUCCESS';
export const GET_ENTRY_FILE_ERROR = 'GET_ENTRY_FILE_ERROR';
export const POSTPONE_ENTRY_EXPIRATION = 'POSTPONE_ENTRY_EXPIRATION';
export const READ_ENTRY = 'READ_ENTRY';
export const READ_ENTRY_SUCCESS = 'READ_ENTRY_SUCCESS';
export const CREATE_READER_LOG = 'CREATE_READER_LOG';
export const CREATE_READER_LOG_SUCCESS = 'CREATE_READER_LOG_SUCCESS';
export const READ_ENTRY_ERROR = 'READ_ENTRY_ERROR';
export const CREATE_ENTRY = 'CREATE_ENTRY';
export const CREATE_ENTRY_SUCCESS = 'CREATE_ENTRY_SUCCESS';
export const CREATE_ENTRY_ERROR = 'CREATE_ENTRY_ERROR';
export const ALL_ENTRIES = 'ALL_ENTRIES';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS';
export const RESTORE_ENTRY = 'RESTORE_ENTRY';
export const RESTORE_ENTRY_SUCCESS = 'RESTORE_ENTRY_SUCCESS';
export const ALL_ENTRIES_SUCCESS = 'ALL_ENTRIES_SUCCESS';
export const ENTRIES_SUMMARY = 'ENTRIES_SUMMARY';
export const ENTRIES_SUMMARY_SUCCESS = 'ENTRIES_SUMMARY_SUCCESS';
export const CREATE_ISSUE_REPORT = 'CREATE_ISSUE_REPORT';
export const CREATE_ISSUE_REPORT_SUCCESS = 'CREATE_ISSUE_REPORT_SUCCESS';

// USER
export const SEND_RECOVERY_CODE = 'SEND_RECOVERY_CODE';
export const SEND_RECOVERY_CODE_SUCCESS = 'SEND_RECOVERY_CODE_SUCCESS';
export const VERIFY_RECOVERY_CODE = 'VERIFY_RECOVERY_CODE';
export const VERIFY_RECOVERY_CODE_SUCCESS = 'SEND_RECOVERY_CODE_SUCCESS';
export const VERIFY_RECOVERY_CODE_ERROR = 'VERIFY_RECOVERY_CODE_ERROR';
export const USER_SUMMARY = 'USER_SUMMARY';
export const USER_SUMMARY_SUCCESS = 'USER_SUMMARY_SUCCESS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_ERROR =
  'RESEND_VERIFICATION_EMAIL_ERROR';
export const MARK_NOTIFICATIONS_AS_SEEN = 'MARK_NOTIFICATIONS_AS_SEEN';
export const MARK_NOTIFICATIONS_AS_SEEN_SUCCESS =
  'MARK_NOTIFICATIONS_AS_SEEN_SUCCESS';

// ORGANIZATION
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';
export const ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS';
export const ALL_ORGANIZATIONS_SUCCESS = 'ALL_ORGANIZATIONS_SUCCESS';
export const ALL_ORGANIZATIONS_ERROR = 'ALL_ORGANIZATIONS_ERROR';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const ASSOCIATE_ORGANIZATION = 'ASSOCIATE_ORGANIZATION';
export const ASSOCIATE_ORGANIZATION_SUCCESS = 'ASSOCIATE_ORGANIZATION_SUCCESS';
export const ASSOCIATE_ORGANIZATION_ERROR = 'ASSOCIATE_ORGANIZATION_ERROR';
export const DISSOCIATE_ORGANIZATION = 'DISSOCIATE_ORGANIZATION';
export const DISSOCIATE_ORGANIZATION_SUCCESS =
  'DISSOCIATE_ORGANIZATION_SUCCESS';
export const DISSOCIATE_ORGANIZATION_ERROR = 'DISSOCIATE_ORGANIZATION_ERROR';

// TASKS
export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_ADMIN = 'GET_TASKS_ADMIN';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASK_URL = 'GET_TASK_URL';
export const GET_TASK_URL_SUCCESS = 'GET_TASK_URL_SUCCESS';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';
export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DOWNLOAD_TASK = 'DOWNLOAD_TASK';
export const DOWNLOAD_TASK_SUCCESS = 'DOWNLOAD_TASK_SUCCESS';
export const DOWNLOAD_TASK_CLEAR = 'DOWNLOAD_TASK_CLEAR';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const UPDATE_TASK_STATUS_SUCCESS = 'UPDATE_TASK_STATUS_SUCCESS';
// PAYMENTS
export const CLEAR_PRICING_PLAN = 'CLEAR_PRICING_PLAN';
export const ALL_PRICING_PLANS = 'ALL_PRICING_PLANS';
export const ALL_PRICING_PLANS_SUCCESS = 'ALL_PRICING_PLANS_SUCCESS';
export const ALL_PRICING_PLANS_ERROR = 'ALL_PRICING_PLANS_ERROR';
export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS';
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'GET_ALL_SUBSCRIPTIONS_SUCCESS';
export const GET_ALL_SUBSCRIPTIONS_ERROR = 'GET_ALL_SUBSCRIPTIONS_ERROR';
export const GET_PRICING_PLAN = 'GET_PRICING_PLAN';
export const GET_PRICING_PLAN_SUCCESS = 'GET_PRICING_PLAN_SUCCESS';
export const GET_PRICING_PLAN_ERROR = 'GET_PRICING_PLAN_ERROR';
export const ADD_PRICING_PLAN = 'ADD_PRICING_PLAN';
export const ADD_PRICING_PLAN_SUCCESS = 'ADD_PRICING_PLAN_SUCCESS';
export const ADD_PRICING_PLAN_ERROR = 'ADD_PRICING_PLAN_ERROR';
export const TOGGLE_PRICING_PLAN = 'TOGGLE_PRICING_PLAN';
export const TOGGLE_PRICING_PLAN_SUCCESS = 'TOGGLE_PRICING_PLAN_SUCCESS';
export const TOGGLE_PRICING_PLAN_ERROR = 'TOGGLE_PRICING_PLAN_ERROR';
export const EDIT_PRICING_PLAN = 'EDIT_PRICING_PLAN';
export const EDIT_PRICING_PLAN_SUCCESS = 'EDIT_PRICING_PLAN_SUCCESS';
export const EDIT_PRICING_PLAN_ERROR = 'EDIT_PRICING_PLAN_ERROR';
export const SUBSCRIBE_PRICING_PLAN = 'SUBSCRIBE_PRICING_PLAN';
export const SUBSCRIBE_PRICING_PLAN_SUCCESS = 'SUBSCRIBE_PRICING_PLAN_SUCCESS';
export const SUBSCRIBE_PRICING_PLAN_ERROR = 'SUBSCRIBE_PRICING_PLAN_ERROR';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';
// ADMIN
export const ADMIN_SUMMARY = 'ADMIN_SUMMARY';
export const ADMIN_SUMMARY_SUCCESS = 'ADMIN_SUMMARY_SUCCESS';
export const ALL_PUBLISHERS = 'ALL_PUBLISHERS';
export const ALL_PUBLISHERS_SUCCESS = 'ALL_PUBLISHERS_SUCCESS';
export const DELETE_PUBLISHER = 'DELETE_PUBLISHER';
export const DELETE_PUBLISHER_ERROR = 'DELETE_PUBLISHER_ERROR';
export const DELETE_PUBLISHER_SUCCESS = 'DELETE_PUBLISHER_SUCCESS';
export const RESTORE_PUBLISHER = 'RESTORE_PUBLISHER';
export const RESTORE_PUBLISHER_ERROR = 'RESTORE_PUBLISHER_ERROR';
export const RESTORE_PUBLISHER_SUCCESS = 'RESTORE_PUBLISHER_SUCCESS';
export const TEMPLATES_USAGE_REPORT = 'TEMPLATES_USAGE_REPORT';
export const TEMPLATES_USAGE_REPORT_SUCCESS = 'TEMPLATES_USAGE_REPORT_SUCCESS';
export const READER_LOGS_REPORT = 'READER_LOGS_REPORT';
export const READER_LOGS_REPORT_SUCCESS = 'READER_LOGS_REPORT_SUCCESS';
export const PUBLISHERS_REGISTRATION_REPORT = 'PUBLISHERS_REGISTRATION_REPORT';
export const PUBLISHERS_REGISTRATION_REPORT_SUCCESS =
  'PUBLISHERS_REGISTRATION_REPORT_SUCCESS';
export const ENTRIES_CREATION_REPORT = 'ENTRIES_CREATION_REPORT';
export const ENTRIES_CREATION_REPORT_SUCCESS =
  'ENTRIES_CREATION_REPORT_SUCCESS';
export const GET_USER_TRANSACTION_HISTORY = 'GET_USER_TRANSACTION_HISTORY';
export const GET_USER_TRANSACTION_HISTORY_SUCCESS =
  'GET_USER_TRANSACTION_HISTORY_SUCCESS';
export const CLEAR_TRANSACTION_HISTORY = 'CLEAR_TRANSACTION_HISTORY';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
