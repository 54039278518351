/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { decryptOnClient, encryptOnClient } from 'services/crypto';
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getCurrentUser, getUTCTimestamp, handleErrorMessage, isUserAdmin } from 'helpers/Utils';
import { ADD_TASK, DELETE_TASK, DOWNLOAD_TASK, GET_TASKS, GET_TASKS_ADMIN, GET_TASK_URL, UPDATE_TASK, UPDATE_TASK_STATUS } from 'redux/contants';
import { addTaskSuccess, deleteTaskSuccess, downloadTaskSuccess, getTaskUrlSuccess, getTasksSuccess, updateTaskStatusSuccess } from 'redux/actions';
import { ADD_TASK_QUERY, DELETE_TASK_QUERY, DOWNLOAD_TASK_QUERY, GET_TASKS_ADMIN_QUERY, GET_TASKS_QUERY, GET_TASK_URL_QUERY, UPDATE_TASK_QUERY, UPDATE_TASK_STATUS_QUERY } from './queries';

export function* watchGetTasks() {
  yield takeLatest(GET_TASKS, getTasksGenerator);
}
const GET_TASKS_API = async (args) => {
  return apolloClient.query({
    query: GET_TASKS_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit
    }
  });
}
function* getTasksGenerator({payload}) {
  try {
    const { data: { getTasks }, errors } = yield call(GET_TASKS_API, payload?.args);
    yield put(getTasksSuccess(getTasks));
  } catch (e) {
    handleErrorMessage(e, 'Get Tasks Error', null);
  }
}

export function* watchGetTasksAdmin() {
  yield takeLatest(GET_TASKS_ADMIN, getTasksAdminGenerator);
}
const GET_TASKS_ADMIN_API = async (args) => {
  return apolloClient.query({
    query: GET_TASKS_ADMIN_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit
    }
  });
}
function* getTasksAdminGenerator({ payload }) {
  try {
    const { data: { getTasksAdmin }, errors } = yield call(GET_TASKS_ADMIN_API, payload?.args);
    yield put(getTasksSuccess(getTasksAdmin));
  } catch (e) {
    handleErrorMessage(e, 'Get Tasks Error', null);
  }
}

export function* watchAddTask() {
  yield takeLatest(ADD_TASK, addTaskGenerator);
}
const ADD_TASK_API = async (args) => {
  return apolloClient.mutate({
    mutation: ADD_TASK_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit,
      taskSlug: args?.taskSlug,
      taskType: args?.taskType,
      text: args?.text,
      filename: args?.filename,
      filenameOriginal: args.filenameOriginal ? encryptOnClient(args.filenameOriginal) : null,
      fileSize: args?.fileSize,
      fileType: args?.fileType,
      metadata: encryptOnClient(JSON.stringify([{
        action: 'add_task',
        user_id: getCurrentUser()?.user?.id,
        comments: '',
        is_admin: isUserAdmin(),
        datetime: getUTCTimestamp(),
      }])),
    }
  });
}
function* addTaskGenerator({ payload }) {
  try {
    const { data: { addTask } } = yield call(ADD_TASK_API, payload.args);
    yield put(addTaskSuccess(addTask));
  } catch (e) {
    handleErrorMessage(e, 'Add Task Error', null);
  }
}

export function* watchUpdateTask() {
  yield takeLatest(UPDATE_TASK, updateTaskGenerator);
}
const UPDATE_TASK_API = async (args) => {
  const currentMetaData = [
    {
      action: 'update_task',
      user_id: getCurrentUser()?.user?.id,
      comments: '',
      is_admin: isUserAdmin(),
      datetime: getUTCTimestamp(),
    }
  ];
  const previousMetaData = JSON.parse(decryptOnClient(args?.metadata));
  return apolloClient.mutate({
    mutation: UPDATE_TASK_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit,
      id: args?.id,
      taskSlug: args?.taskSlug,
      taskType: args?.taskType,
      text: args?.text,
      filename: args?.filename,
      filenameOriginal: args?.filenameOriginal,
      fileSize: args?.fileSize,
      fileType: args?.fileType,
      metadata: encryptOnClient(JSON.stringify(currentMetaData.concat(previousMetaData))),
    }
  });
}
function* updateTaskGenerator({ payload }) {
  try {
    const { data: { updateTask } } = yield call(UPDATE_TASK_API, payload.args);
    yield put(addTaskSuccess(updateTask));
  } catch (e) {
    handleErrorMessage(e, 'Update Task Error', null);
  }
}

export function* watchGetTaskUrl() {
  yield takeLatest(GET_TASK_URL, getTaskUrlGenerator);
}
const GET_TASK_URL_API = async (args) => {
  return apolloClient.query({
    query: GET_TASK_URL_QUERY,
    variables: {
      type: args?.type
    }
  });
}
function* getTaskUrlGenerator({ payload }) {
  try {
    const { data: { getTaskUrl } } = yield call(GET_TASK_URL_API, payload.args);
    yield put(getTaskUrlSuccess(getTaskUrl));
  } catch (e) {
    handleErrorMessage(e, 'Get Task URL Error', null);
  }
}

export function* watchDeleteTask() {
  yield takeLatest(DELETE_TASK, deleteTaskGenerator);
}
const DELETE_TASK_API = async (args) => {
  const currentMetaData = [
    {
      action: 'delete_task',
      user_id: getCurrentUser()?.user?.id,
      comments: '',
      is_admin: isUserAdmin(),
      datetime: getUTCTimestamp(),
    }
  ];
  const previousMetaData = JSON.parse(decryptOnClient(args?.metadata));
  return apolloClient.mutate({
    mutation: DELETE_TASK_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit,
      deleteTaskId: args?.id,
      metadata: encryptOnClient(JSON.stringify(currentMetaData.concat(previousMetaData))),
    }
  });
}
function* deleteTaskGenerator({ payload }) {
  try {
    const { data: {deleteTask} } = yield call(DELETE_TASK_API, payload.args);
    yield put(deleteTaskSuccess(deleteTask));
  } catch (e) {
    handleErrorMessage(e, 'Delete Task Error', null);
  }
}

export function* watchDownloadTask() {
  yield takeLatest(DOWNLOAD_TASK, downloadTaskGenerator);
}
const DOWNLOAD_TASK_API = async (args) => {
  return apolloClient.query({
    query: DOWNLOAD_TASK_QUERY,
    variables: {
      task_id: args?.taskId,
    }
  });
}
function* downloadTaskGenerator({ payload }) {
  try {
    const { data: {downloadTask} } = yield call(DOWNLOAD_TASK_API, payload.args);
    yield put(downloadTaskSuccess(downloadTask));
  } catch (e) {
    handleErrorMessage(e, 'Download Task Error', null);
  }
}

export function* watchUpdateTaskStatus() {
  yield takeLatest(UPDATE_TASK_STATUS, updateTaskStatusGenerator);
}
const UPDATE_TASK_STATUS_API = async (args) => {
  const currentMetaData = [
    {
      action: 'update_task_status',
      user_id: getCurrentUser()?.user?.id,
      comments: args?.comments,
      is_admin: isUserAdmin(),
      datetime: getUTCTimestamp(),
    }
  ];
  const previousMetaData = JSON.parse(decryptOnClient(args?.metadata));
  return apolloClient.mutate({
    mutation: UPDATE_TASK_STATUS_QUERY,
    variables: {
      statusFilter: args?.statusFilter,
      page: args?.page,
      limit: args?.limit,
      comments: args?.comments,
      status: args?.status,
      updateTaskStatusId: args?.id,
      metadata: encryptOnClient(JSON.stringify(currentMetaData.concat(previousMetaData))),
    }
  });
}
function* updateTaskStatusGenerator({ payload }) {
  try {
    const { data: {updateTaskStatus} } = yield call(UPDATE_TASK_STATUS_API, payload.args);
    yield put(updateTaskStatusSuccess(updateTaskStatus));
  } catch (e) {
    handleErrorMessage(e, 'Update Task Status Error', null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTasks),
    fork(watchAddTask),
    fork(watchGetTaskUrl),
    fork(watchDeleteTask),
    fork(watchDownloadTask),
    fork(watchGetTasksAdmin),
    fork(watchUpdateTaskStatus),
    fork(watchUpdateTask),
  ]);
}
