/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { handleErrorMessage, handleSuccessMessage } from 'helpers/Utils';
import {
  ADMIN_SUMMARY,
  ALL_PUBLISHERS,
  DELETE_PUBLISHER,
  TEMPLATES_USAGE_REPORT,
  RESTORE_PUBLISHER,
  READER_LOGS_REPORT,
  PUBLISHERS_REGISTRATION_REPORT,
  ENTRIES_CREATION_REPORT,
  CREATE_ORGANIZATION,
  ALL_ORGANIZATIONS,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  ASSOCIATE_ORGANIZATION,
  DISSOCIATE_ORGANIZATION,
} from 'redux/contants';
import {
  adminSummarySuccess,
  allPublishersSuccess,
  deletePublisherSuccess,
  getEntriesCreationReportSuccess,
  getPublishersRegistrationReportSuccess,
  getReaderLogsReportSuccess,
  getTemplatesUsageReportSuccess,
  restorePublisherSuccess,
} from 'redux/actions';
import {
  ADMIN_SUMMARY_QUERY,
  ALL_PUBLISHERS_QUERY,
  DELETE_PUBLISHER_QUERY,
  TEMPLATES_USAGE_REPORT_QUERY,
  RESTORE_PUBLISHER_QUERY,
  READER_LOGS_REPORT_QUERY,
  PUBLISHERS_REGISTRATION_REPORT_QUERY,
  ENTRIES_CREATION_REPORT_QUERY,
  CREATE_ORGANIZATION_QUERY,
  ALL_ORGANIZATIONS_QUERY,
  DELETE_ORGANIZATION_QUERY,
  UPDATE_ORGANIZATION_QUERY,
  ASSOCIATE_ORGANIZATION_QUERY,
  DISSOCIATE_ORGANIZATION_QUERY,
} from './queries';
import {
  allOrganizationsSuccess,
  associateOrganizationSuccess,
  createOrganizationSuccess,
  deleteOrganizationSuccess,
  dissociateOrganizationSuccess,
  updateOrganizationSuccess,
} from './actions';

export function* watchAdminSummary() {
  yield takeLatest(ADMIN_SUMMARY, adminSummaryGenerator);
}
const ADMIN_SUMMARY_API = async () => {
  return apolloClient.query({
    query: ADMIN_SUMMARY_QUERY,
    variables: {},
  });
};
function* adminSummaryGenerator() {
  try {
    const {
      data: { adminSummary },
    } = yield call(ADMIN_SUMMARY_API);
    yield put(adminSummarySuccess(adminSummary));
  } catch (e) {
    handleErrorMessage(e, 'Admin Summary Error', null);
  }
}

export function* watchTemplatesUsageReport() {
  yield takeLatest(TEMPLATES_USAGE_REPORT, templatesUsageReportGenerator);
}
const TEMPLATES_USAGE_REPORT_API = async (args) => {
  return apolloClient.query({
    query: TEMPLATES_USAGE_REPORT_QUERY,
    variables: {
      createdAtStart: args.createdAtStart,
      createdAtEnd: args.createdAtEnd,
    },
  });
};
function* templatesUsageReportGenerator({ payload }) {
  try {
    const {
      data: { templatesUsageReport },
    } = yield call(TEMPLATES_USAGE_REPORT_API, payload.args);
    yield put(getTemplatesUsageReportSuccess(templatesUsageReport));
  } catch (e) {
    handleErrorMessage(e, 'Templates Usage Report Error', null);
  }
}

export function* watchReaderLogsReport() {
  yield takeLatest(READER_LOGS_REPORT, readerLogsReportGenerator);
}
const READER_LOGS_REPORT_API = async (args) => {
  return apolloClient.query({
    query: READER_LOGS_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* readerLogsReportGenerator({ payload }) {
  try {
    const {
      data: { readerLogsReport },
    } = yield call(READER_LOGS_REPORT_API, payload.args);
    yield put(getReaderLogsReportSuccess(readerLogsReport));
  } catch (e) {
    handleErrorMessage(e, 'Reader Logs Report Error', null);
  }
}

export function* watchPublishersRegistrationReport() {
  yield takeLatest(
    PUBLISHERS_REGISTRATION_REPORT,
    publishersRegistrationReportGenerator
  );
}
const PUBLISHERS_REGISTRATION_REPORT_API = async (args) => {
  return apolloClient.query({
    query: PUBLISHERS_REGISTRATION_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* publishersRegistrationReportGenerator({ payload }) {
  try {
    const {
      data: { publishersRegistrationReport },
    } = yield call(PUBLISHERS_REGISTRATION_REPORT_API, payload.args);
    yield put(
      getPublishersRegistrationReportSuccess(publishersRegistrationReport)
    );
  } catch (e) {
    handleErrorMessage(e, 'Publishers Registration Report Error', null);
  }
}

export function* watchEntriesCreationReport() {
  yield takeLatest(ENTRIES_CREATION_REPORT, entriesCreationReportGenerator);
}
const ENTRIES_CREATION_REPORT_API = async (args) => {
  return apolloClient.query({
    query: ENTRIES_CREATION_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* entriesCreationReportGenerator({ payload }) {
  try {
    const {
      data: { entriesCreationReport },
    } = yield call(ENTRIES_CREATION_REPORT_API, payload.args);
    yield put(getEntriesCreationReportSuccess(entriesCreationReport));
  } catch (e) {
    handleErrorMessage(e, 'Entries Creation Report Error', null);
  }
}

export function* watchAllPublishers() {
  yield takeLatest(ALL_PUBLISHERS, allPublishersGenerator);
}
const ALL_PUBLISHERS_API = async (args) => {
  return apolloClient.query({
    query: ALL_PUBLISHERS_QUERY,
    variables: {
      limit: args?.limit,
      page: args?.page,
      search: args?.search,
      orderByField: args?.orderByField,
      orderDirection: args?.orderDirection,
      paranoid: args?.paranoid,
    },
  });
};
function* allPublishersGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { allUsers },
    } = yield call(ALL_PUBLISHERS_API, args);
    yield put(allPublishersSuccess(allUsers));
  } catch (e) {
    handleErrorMessage(e, 'Error fetching publishers', null);
  }
}

export function* watchDeletePublisher() {
  yield takeLatest(DELETE_PUBLISHER, deletePublisherGenerator);
}
const DELETE_PUBLISHER_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_PUBLISHER_QUERY,
    variables: { deleteUserByIdId: args?.id },
  });
};
function* deletePublisherGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { deleteUserById },
    } = yield call(DELETE_PUBLISHER_API, args);
    handleSuccessMessage(deleteUserById, 'Account Deleted');
    const state = yield select();
    const publishers = state?.admin?.publishers;
    const updatedPublishers = publishers?.rows?.map((p) => {
      if (p.id !== args.id) return p;
      return {
        ...p,
        deleted_at: new Date().getTime().toString(),
      };
    });
    yield put(
      deletePublisherSuccess({
        ...publishers,
        rows: updatedPublishers,
      })
    );
  } catch (e) {
    handleErrorMessage(e, 'Error deleting publisher account', null);
  }
}

export function* watchRestorePublisher() {
  yield takeLatest(RESTORE_PUBLISHER, restorePublisherGenerator);
}
const RESTORE_PUBLISHER_API = async (args) => {
  return apolloClient.mutate({
    mutation: RESTORE_PUBLISHER_QUERY,
    variables: { restoreUserByIdId: args?.id },
  });
};
function* restorePublisherGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { restoreUserById },
    } = yield call(RESTORE_PUBLISHER_API, args);
    handleSuccessMessage(restoreUserById, 'Account Restored');
    const state = yield select();
    const publishers = state?.admin?.publishers;
    const updatedPublishers = publishers?.rows?.map((p) => {
      if (p.id !== args.id) return p;
      return {
        ...p,
        deleted_at: null,
      };
    });
    yield put(
      restorePublisherSuccess({
        ...publishers,
        rows: updatedPublishers,
      })
    );
  } catch (e) {
    handleErrorMessage(e, 'Error restoring publisher account', null);
  }
}

export function* watchCreateOrganization() {
  yield takeLatest(CREATE_ORGANIZATION, createOrganizationGenerator);
}
const CREATE_ORGANIZATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: CREATE_ORGANIZATION_QUERY,
    variables: { name: args?.name, details: args?.details },
  });
};
function* createOrganizationGenerator({ payload }) {
  const { args, history } = payload;
  try {
    yield call(CREATE_ORGANIZATION_API, args);
    handleSuccessMessage(
      'New organization has been created successfully!',
      'New Organization Created'
    );
    yield put(createOrganizationSuccess());
    history.push('/admin/organizations');
  } catch (e) {
    handleErrorMessage(e, 'Error creating new organization', null);
  }
}

export function* watchAllOrganizations() {
  yield takeLatest(ALL_ORGANIZATIONS, allOrganizationsGenerator);
}
const ALL_ORGANIZATIONS_API = async (args) => {
  return apolloClient.query({
    query: ALL_ORGANIZATIONS_QUERY,
    variables: {
      limit: args?.limit,
      search: args?.search,
      page: args?.page,
      orderByField: args?.orderByField,
      orderDirection: args?.orderDirection,
    },
  });
};
function* allOrganizationsGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { allOrganizations },
    } = yield call(ALL_ORGANIZATIONS_API, args);
    yield put(allOrganizationsSuccess(allOrganizations));
  } catch (e) {
    handleErrorMessage(e, 'Error fetching all organizations', null);
  }
}

export function* watchDeleteOrganization() {
  yield takeLatest(DELETE_ORGANIZATION, deleteOrganizationGenerator);
}
const DELETE_ORGANIZATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_ORGANIZATION_QUERY,
    variables: {
      deleteOrganizationId: args?.id,
    },
  });
};
function* deleteOrganizationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { deleteOrganization },
    } = yield call(DELETE_ORGANIZATION_API, args);
    yield put(deleteOrganizationSuccess());
    handleSuccessMessage(deleteOrganization, 'New Organization Created');
  } catch (e) {
    handleErrorMessage(e, 'Error deleting organization', null);
  }
}

export function* watchUpdateOrganization() {
  yield takeLatest(UPDATE_ORGANIZATION, updateOrganizationGenerator);
}
const UPDATE_ORGANIZATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: UPDATE_ORGANIZATION_QUERY,
    variables: {
      updateOrganizationId: args?.id,
      name: args?.name,
      details: args?.details,
    },
  });
};
function* updateOrganizationGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const {
      data: { updateOrganization },
    } = yield call(UPDATE_ORGANIZATION_API, args);
    yield put(updateOrganizationSuccess());
    handleSuccessMessage(updateOrganization, 'Organization Updated');
    history.push('/admin/organizations');
  } catch (e) {
    handleErrorMessage(e, 'Error updating organization', null);
  }
}

export function* watchAssociateOrganization() {
  yield takeLatest(ASSOCIATE_ORGANIZATION, associateOrganizationGenerator);
}
const ASSOCIATE_ORGANIZATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: ASSOCIATE_ORGANIZATION_QUERY,
    variables: {
      associateOrganizationId: args?.id,
      organizationId: args?.organization_id,
    },
  });
};
function* associateOrganizationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { associateOrganization },
    } = yield call(ASSOCIATE_ORGANIZATION_API, args);
    yield put(associateOrganizationSuccess());
    handleSuccessMessage(associateOrganization, 'Organization Associated');
  } catch (e) {
    handleErrorMessage(e, 'Error associating organization', null);
  }
}

export function* watchDissociateOrganization() {
  yield takeLatest(DISSOCIATE_ORGANIZATION, dissociateOrganizationGenerator);
}
const DISSOCIATE_ORGANIZATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: DISSOCIATE_ORGANIZATION_QUERY,
    variables: {
      dissociateOrganizationId: args?.id,
    },
  });
};
function* dissociateOrganizationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { dissociateOrganization },
    } = yield call(DISSOCIATE_ORGANIZATION_API, args);
    yield put(dissociateOrganizationSuccess());
    handleSuccessMessage(dissociateOrganization, 'Organization Dissociated');
  } catch (e) {
    handleErrorMessage(e, 'Error dissociating organization', null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAdminSummary),
    fork(watchAllPublishers),
    fork(watchDeletePublisher),
    fork(watchRestorePublisher),
    fork(watchTemplatesUsageReport),
    fork(watchReaderLogsReport),
    fork(watchPublishersRegistrationReport),
    fork(watchEntriesCreationReport),
    fork(watchCreateOrganization),
    fork(watchAllOrganizations),
    fork(watchDeleteOrganization),
    fork(watchUpdateOrganization),
    fork(watchAssociateOrganization),
    fork(watchDissociateOrganization),
  ]);
}
