/* eslint-disable no-unused-vars */
import { NotificationManager } from 'components/common/react-notifications';
import * as EmailValidator from 'email-validator';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
} from 'constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  // TODO: Investigate if we want to bring back some dark mode.
  return defaultColor;
};

export const setCurrentColor = (/* color */) => {
  // TODO: Investigate if we want to bring back some dark mode.
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

// This is actually returning a context, not a user.
// The user is accessible through getCurrentUser().user
// TODO: Consider renaming this, and create a real getCurrentUser() method.

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('current_user') != null
        ? JSON.parse(localStorage.getItem('current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const isUserAdmin = () => {
  if (!getCurrentUser()) return false;
  if (getCurrentUser()?.user?.admin_guid !== null) return true;
  return false;
};

export const getUTCTimestamp = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const updateCurrentUser = (user) => {
  try {
    let currentUser = getCurrentUser();
    currentUser = {
      user,
      token: currentUser.token,
      encryption_key: currentUser.encryption_key,
      encryption_iv: currentUser.encryption_iv,
    };
    setCurrentUser(currentUser);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : updateCurrentUser -> error',
      error
    );
  }
};

export const handleSuccessMessage = (text, title) => {
  const message = text?.message ?? text;
  NotificationManager.success(message, title, 3000, null, false, '');
};

export const handleErrorMessage = (error, title, history) => {
  // Try to look up the GraphQL errors first
  // If there is a message property, handle it gracefully too
  const message = error?.message ?? error;
  // We clean the current list of notifications in case of error. This is not pretty nor perfect,
  // but it avoids the pathological cases of dozens of notifications displayed if the customer
  // clicks to generate an error multiple times.
  if (message !== 'Failed to fetch' && !message.includes('401')) {
    NotificationManager.listNotify = [];
    NotificationManager.error(message, title, 5000, null, true, '');
  }
};

// Expose error constants for unit-tests
export const isFullNameValidError0 = 'Please enter your name.';
export const isFullNameValidError1 =
  'Please enter your name without spaces or punctuations before or after.';
export const isFullNameValidError2 =
  "Invalid name. Please enter only A-Z, 0-9, and '.-_ characters.";
export const isFullNameValidError3 =
  'Invalid name. Enter between 3 and 70 characters.';
export const isEmailValidError0 = 'Please enter your email address';
export const isEmailValidError1 = 'Invalid email address.';
export const isPasswordValidError0 = 'Please enter your password.';
export const isPasswordValidError1 =
  'Password must be between 8 and 32 characters. The longer, the better.';
export const isPasswordValidError2 =
  'Password must be a combination of at least one of these: Upper and lower case letters, numbers, and special characters.';

export function trim(str, chars) {
  let i = 0;
  let j = str.length - 1;
  while (chars.includes(str[i])) i += 1;
  while (chars.includes(str[j])) j -= 1;
  return str.slice(i, j + 1);
}

// TODO: Cheezy API. Improve it. true on success, string on errors.
export function isFullNameValid(text) {
  if (!text) {
    return isFullNameValidError0;
  }
  // Follow the 70 characters limit from the UK government
  // https://webarchive.nationalarchives.gov.uk/ukgwa/20100407211619/http://www.cabinetoffice.gov.uk/govtalk/schemasstandards/e-gif/datastandards/person_information/person_name/person_full_name.aspx
  if (trim(text, " \t-_.'") !== text) {
    return isFullNameValidError1;
  }
  if (text.length < 3 || text.length > 70) {
    return isFullNameValidError3;
  }
  if (!/^['A-Za-z0-9._-\s]+$/.test(text)) {
    return isFullNameValidError2;
  }
  return true;
}

// TODO: Cheezy API. Improve it. true on success, string on errors.
export function isEmailValid(text) {
  if (!text) {
    return isEmailValidError0;
  }
  if (!/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(text)) {
    return isEmailValidError1;
  }
  if (!EmailValidator.validate(text)) {
    return isEmailValidError1;
  }
  return true;
}

// TODO: Cheezy API. Improve it. true on success, string on errors.
export function isPasswordValid(text) {
  if (!text) {
    return isPasswordValidError0;
  }
  if (text.length < 8 || text.length > 32) {
    return isPasswordValidError1;
  }
  if (
    !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*\-_=\\|+(){}[\]<>,./?;:'"])[a-zA-Z0-9`~!@#$%^&*\-_=\\|+(){}[\]<>,./?;:'"]+$/.test(
      text
    )
  ) {
    return isPasswordValidError2;
  }
  return true;
}

export const randomString = (len) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < len) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getAPIAddress = async () => {
  return fetch('https://api.ipify.org/?format=json').then((res) => res.json());
};

export const getLocation = async (ip) => {
  return fetch(`https://ipapi.co/${ip}/json`).then((res) => res.json());
};

export const loginTestUser = async () => {
  const query = `
    mutation LoginUser($email: String!, $password: String!){
      loginUser(email: $email, password: $password){
          user{
              name, email, id, is_email_verified, deleted_at, secondary_email, admin_guid
          },
          token, encryption_key, encryption_iv
      }
    }
  `;
  return fetch('http://localhost:3001/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: {
        email: 'theong@mailinator.com',
        password: 'Theon!23',
      },
    }),
  }).then((res) => res.json());
};
